import { setUser } from '@sentry/react'
import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, merge, of, throwError, timer } from 'rxjs'
import { catchError, filter, mapTo, mergeMap, mergeMapTo } from 'rxjs/operators'
import config from '../../config'
import { getDelayBeforeExpiration, getNow, parseJwt } from '../../helpers/auth'
import { getHub } from '../../helpers/hub'
import { forceReload, logError } from '../ws/actions'
import { getServerTimeDelayMs } from '../ws/selectors'
import {
  AuthAction,
  fetchAuthToken,
  FetchAuthTokenAction,
  fetchAuthTokenFailure,
  fetchAuthTokenSuccess,
  FetchAuthTokenSuccessAction,
  setAuthInitialized
} from './actions'
import {
  getAuthType,
  getIsInitialized,
  getOktaAuth,
  getUserId
} from './selectors'

/*
    Legacy okta auth stores the auth class instance in Redux and then
    uses its methods from the epic. We shouldn't be storing non-serializable
    objects in Redux, and functions (ie instance methods) are not serializable.
    We're deprecating Okta, so I (AB) am not rewriting the okta login flow.
    However, the keycloak flow assumes that the heavy lifting around actually
    signing in and out will be done by the oidc context and the
    UserManager instance it makes.
 */

const getAuthTokenEpic: Epic = (
  action$: ActionsObservable<FetchAuthTokenAction>,
  state$
) =>
  action$.pipe(
    ofType('auth.fetch-auth-token'),
    filter((action) => action.payload.type === 'Okta'),
    mergeMap((action) => {
      const { isRefresh } = action.payload

      const auth = getOktaAuth(state$.value)

      const authToken = auth?.getAccessToken()

      if (!authToken && auth) {
        auth.signInWithRedirect().catch((e) => console.log(e))
        return throwError('No access token')
      }

      const delay = getServerTimeDelayMs(state$.value) || 0
      const expiresIn = getDelayBeforeExpiration(authToken!) - delay
      const refreshIn = Math.max(
        expiresIn - config.api.refreshTokenTimeout,
        // It happens that we want to refresh the token but Okta SDK judges that the token
        // is still valid, so returns the same as before. In this case, we don’t ask for a
        // new refresh immediately, but we wait a second.
        1000
      )
      // tslint:disable-next-line: no-console
      console.log(
        `Auth token refresh at ${new Date(
          getNow() + refreshIn
        ).toLocaleTimeString()}`
      )

      const callApiToRefreshToken$ = isRefresh
        ? getHub().invoke('RefreshToken', authToken).pipe(mergeMapTo(EMPTY))
        : EMPTY
      const scheduleNextTokenRefresh$ = timer(refreshIn).pipe(
        mapTo(fetchAuthToken('Okta', true))
      )

      const parsedToken = parseJwt(authToken!)
      const userName = parsedToken.sub
      const userId = parsedToken.userId
      if (
        state$.value.auth.userId !== undefined &&
        state$.value.auth.userId !== userId
      ) {
        // tslint:disable-next-line: no-console
        console.log('Invalid user in Okta token. Refreshing page.')
        return of(forceReload())
      } else {
        setUser({ id: userId, username: userName, ip_address: '{{auto}}' })
      }
      return merge(
        callApiToRefreshToken$,
        scheduleNextTokenRefresh$,
        of(fetchAuthTokenSuccess(authToken!, userName, userId))
      )
    }),
    catchError((err) => of(fetchAuthTokenFailure(err), logError(err)))
  )

const keycloakAuthRefreshedEpic: Epic = (
  action$: ActionsObservable<FetchAuthTokenSuccessAction>,
  state$
) =>
  action$.pipe(
    ofType('auth.fetch-auth-token-success'),
    filter((_action) => getAuthType(state$.value) === 'Keycloak'),
    mergeMap((action) => {
      const { authToken, userName } = action.payload
      const storedUserId = getUserId(state$.value)
      const parsedToken = parseJwt(authToken)
      const userId = parsedToken.sid

      if (storedUserId && storedUserId !== userId) {
        // tslint:disable-next-line: no-console
        console.log('Invalid user in OIDC token. Refreshing page.')
        return of(forceReload())
      } else {
        // tslint:disable-next-line: no-console
        console.log('refreshing token')
        // sentry setup
        setUser({ id: userId, username: userName, ip_address: '{{auto}}' })
      }

      if (getIsInitialized(state$.value)) {
        return getHub()
          .invoke('RefreshToken', authToken)
          .pipe(mergeMapTo(EMPTY))
      }
      return of(setAuthInitialized())
    })
  )

const redirectToLoginEpic: Epic<AuthAction> = (action$, state$) =>
  action$.pipe(
    ofType('auth.redirect-to-login'),
    filter((_action) => getAuthType(state$.value) === 'Okta'),
    mergeMap(() => of(getOktaAuth(state$.value)?.signInWithRedirect())),
    mergeMapTo(EMPTY)
  )

export default combineEpics(
  getAuthTokenEpic,
  redirectToLoginEpic,
  keycloakAuthRefreshedEpic
)
