import cx from 'classnames'
import React, { ReactNode, useMemo } from 'react'
import { formatAggressorLLPrice } from '../../helpers/formatting'
import { SecurityModalInfo } from '../../store/windows/types'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'

import styles from '../Modal/modal.module.scss'

interface Props {
  currency: string
  isTreasury: boolean
  amountValueStyles?: string
  priceValueStyles?: string
  securityModalInfo: SecurityModalInfo
  selectedAccount?: string
  setSelectedAccount?: (st: string) => void
  tradeAmt: ReactNode
  updateOnChanges: Array<SecurityModalInfo[keyof SecurityModalInfo]>
}

const LeftInfoPanel = ({
  currency,
  isTreasury,
  amountValueStyles,
  priceValueStyles,
  securityModalInfo,
  selectedAccount,
  setSelectedAccount,
  tradeAmt,
  updateOnChanges
}: Props) => {
  const localSecurityModalInfo = useMemo(() => {
    return {
      ...securityModalInfo
    }
  }, [...updateOnChanges])
  const accountOptions = useMemo(
    () => Object.values(securityModalInfo.accountIdToName).sort(),
    [securityModalInfo]
  )
  const priceFieldStyles = cx(priceValueStyles, styles.value)
  const amountFieldStyles = cx(amountValueStyles, styles.value)
  return (
    <div className={styles.leftInfos}>
      <div className={styles.row}>
        <div>
          <label>Trade Amt</label>
          {tradeAmt}
        </div>
        <div>
          <p>Spread</p>
          <p className={styles.value}>{securityModalInfo.spread}</p>
        </div>
      </div>
      {selectedAccount && setSelectedAccount && (
        <div className={styles.accountLine}>
          Account:
          <GenericDropdownMenu
            selectedItem={selectedAccount}
            setSelectedItem={setSelectedAccount}
            options={accountOptions}
            selectId={''}
            openRight={true}
            className={''}
          />
        </div>
      )}
      <div className={styles.row}>
        <div>
          <p>Available Amt</p>
          <p data-testid="aggressor-amt" className={amountFieldStyles}>
            {securityModalInfo.maximumSize}
          </p>
        </div>
        <div>
          <p>Price</p>
          <p data-testid="aggressor-price" className={priceFieldStyles}>
            {formatAggressorLLPrice(
              securityModalInfo.price,
              currency,
              isTreasury
            )}
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p>*Bench Price</p>
          <p data-testid="aggressor-bench-price" className={styles.value}>
            {localSecurityModalInfo.benchmarkPrice}
          </p>
        </div>
        <div>
          <p>YTW</p>
          <p data-testid="aggressor-YTW" className={priceFieldStyles}>
            {localSecurityModalInfo.benchmarkPrice}
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p>Hedge Notional</p>
          <p data-testid="aggressor-hedge-notional" className={styles.value}>
            {securityModalInfo.hedgeNotional}
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.disclaimer}>
          <p>*Treasury execution/hedge may occur at a different price</p>
        </div>
      </div>
    </div>
  )
}

export default LeftInfoPanel
