import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SecuritySearchResult } from '../../../store/searchSecurities/types'
import {
  fetchAdminWatchListsAction,
  fetchAdminWatchlistsByIdentifierAction,
  fetchAdminWatchlistsByUserAction
} from '../../../store/watchList/actions'
import RadioButtonGroup from '../../RadioButtonGroup/RadioButtonGroup'
import CusipIsinSearch from '../../SearchInput/CusipIsinSearch'
import UserSearch from '../../SearchInput/UserSearch'

import styles from './WatchlistManager.module.scss'

type SearchProps = {
  onSearch: () => void
}
const SearchWatchlistByUser = ({ onSearch }: SearchProps) => {
  const dispatch = useDispatch()
  const onUserSelect = useCallback((userId: number) => {
    dispatch(fetchAdminWatchlistsByUserAction(userId))
    onSearch()
  }, [])
  const onClear = useCallback(() => {
    dispatch(fetchAdminWatchListsAction())
    onSearch()
  }, [])
  return <UserSearch onUserSelect={onUserSelect} onClear={onClear} />
}
const SearchWatchlistBySecurity = ({ onSearch }: SearchProps) => {
  const dispatch = useDispatch()
  const [selectedSecurity, setSelectedSecurity] =
    useState<SecuritySearchResult>()
  const onSecuritySelect = useCallback(
    (security: SecuritySearchResult) => {
      // prevents infinite loop if there's an error in watchlist search
      if (security.id !== selectedSecurity?.id) {
        dispatch(fetchAdminWatchlistsByIdentifierAction(security.isin))
        setSelectedSecurity(security)
        onSearch()
      }
    },
    [selectedSecurity]
  )
  const onClear = useCallback(() => {
    dispatch(fetchAdminWatchListsAction())
    onSearch()
  }, [])
  return (
    <CusipIsinSearch onSecuritySelect={onSecuritySelect} onClear={onClear} />
  )
}

type WatchListManagerHeaderProps = SearchProps & {
  createNewWatchlist: () => void
}

const WatchListManagerHeader = ({
  onSearch,
  createNewWatchlist
}: WatchListManagerHeaderProps) => {
  const [searchType, setSearchType] = useState('user')

  const radioProps = useMemo(() => {
    return {
      legend: 'Search by:',
      options: [
        { label: 'User Name', value: 'user' },
        { label: 'Cusip/Isin', value: 'symbol' }
      ],
      selectedOption: searchType,
      testPrefix: 'watchlist-manager-search',
      onSelect: setSearchType
    }
  }, [searchType])
  return (
    <header>
      <div className={styles.controls}>
        <RadioButtonGroup {...radioProps} />
        <div className={styles.searchWrapper}>
          {searchType === 'user' ? (
            <SearchWatchlistByUser onSearch={onSearch} />
          ) : (
            <SearchWatchlistBySecurity onSearch={onSearch} />
          )}
        </div>
      </div>
      <button data-testid="admin-watchlist-create" onClick={createNewWatchlist}>
        Create New
      </button>
    </header>
  )
}

export default WatchListManagerHeader
