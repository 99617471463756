import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

type T = typeof namespace

type HasNsKey = {
  [key in T]: State
}
const getState = (state: HasNsKey) => state[namespace]

export const searchIsPending = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.searches[gridIndex].pending
)

export const searchHasError = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.searches[gridIndex].error
)

export const getSearchResults = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.searches[gridIndex]?.results ?? []
)

export const getSearchResultsIgnoringWatchlist = createSelector(
  [getState],
  (state) => (gridIndex: number) =>
    state.searches[gridIndex]?.resultsIgnoringWatchlist ?? []
)

export const getGlobalSearchResults = createSelector(
  [getState],
  (state) => state.globalSearch.results
)
