import { CustomDetailCellRendererProps } from '@ag-grid-community/react'
import React, { useEffect } from 'react'
import { fetchSecuritiesByIds } from '../../../store/securities/actions'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import AdminTools from '../../DepthOfMarket/Depth/AdminTools'
import styles from '../../DepthOfMarket/Depth/DepthOfMarket.module.scss'
import { PassiveDepthOfMarket } from '../../DepthOfMarket/PassiveDepthOfMarket'

const AggressDetailRenderer = ({
  data,
  pinned
}: CustomDetailCellRendererProps<SecurityStaticData>) => {
  const dispatch = useAppDispatch()
  const id = data?.id ?? -1
  const security = useAppSelector(getSecurityStaticDataById)(id)

  useEffect(() => {
    if (!security) {
      dispatch(fetchSecuritiesByIds([id]))
    }
  }, [security])

  if (!security || pinned) return null
  return (
    <div className={styles.aggressAttemptsSecuritySection}>
      <PassiveDepthOfMarket
        security={security}
        gridIndex={0}
        isPopout={false}
        showOrderEntry={false}
        extraColumns={[AdminTools]}
      />
    </div>
  )
}

export default AggressDetailRenderer
