import cx from 'classnames'
import React from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer'
import DepthOfMarket from '../../DepthOfMarket/Depth/DepthOfMarket'
import depthStyles from '../../DepthOfMarket/Depth/DepthOfMarket.module.scss'
import ExpandableComponent from '../../ExpandableComponent/ExpandableComponent'
import titleStyles from '../ModalSecurityInformationWrapper.module.scss'
import ExpandableSectionTitle from '../../ExpandableComponent/ExpandableSectionTitle'
import styles from './lastLookDepth.module.css'

interface Props {
  security: SecurityStaticData
}

const TitleComponent = (
  isExpanded: boolean,
  setExpanded: (expanded: boolean) => void
) => {
  return (
    <ExpandableSectionTitle
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      className={titleStyles.securityInfoTitle}
    >
      Depth of Market
    </ExpandableSectionTitle>
  )
}

const LastLookDepth = ({ security }: Props) => {
  return (
    <ExpandableComponent title={TitleComponent} isInitiallyExpanded={false}>
      <DepthOfMarket
        security={security}
        isPopout={true}
        gridIndex={0}
        className={cx(depthStyles.depthOfMarket, styles.lastLookDepth)}
      />
    </ExpandableComponent>
  )
}

export default LastLookDepth
