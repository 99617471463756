import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import App from '../app/App'
import styles from '../assets/scss/global.module.scss'
import config from '../config'
import ProtectedRouteParent from './ProtectedRouteParent'
import { SessionContext } from './SessionContext'

const oktaAuth = new OktaAuth(config.okta)

const OktaWrapper = () => {
  const navigate = useNavigate()

  const restoreOriginalUri = useCallback(
    (_oktaAuth: any, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin))
    },
    []
  )
  const sessionContextProps = useMemo(() => {
    return {
      signout: () => {
        oktaAuth?.signOut({ postLogoutRedirectUri: null })
      }
    }
  }, [])

  return (
    <Security
      className={styles.wrapper}
      pkce={true}
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <SessionContext.Provider value={sessionContextProps}>
        <App SecureRoute={ProtectedRouteParent} />
      </SessionContext.Provider>
    </Security>
  )
}

export default OktaWrapper
