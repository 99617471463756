import { useCallback, useEffect, useMemo } from 'react'
import { useDebounce } from 'react-use'
import {
  clearGlobalSearch,
  globalSearch
} from '../../store/searchSecurities/actions'
import { getGlobalSearchResults } from '../../store/searchSecurities/selectors'
import { getSecurityByCusipOrIsin } from '../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { fetchSymbolDetails } from '../../store/upload/actions'

const addUnique = <T>(arr: T[], val: T) => {
  if (!arr.includes(val)) {
    arr.push(val)
  }
  return arr
}

export const useSecuritySymbols = (
  searchString: string,
  omitIssuers?: boolean
) => {
  const dispatch = useAppDispatch()
  const rawSearchResults = useAppSelector(getGlobalSearchResults)
  const findSecurityBySymbol = useAppSelector(getSecurityByCusipOrIsin)

  const getBoardLabelForSymbol = useCallback(
    (symbol: string) => {
      return findSecurityBySymbol(symbol?.toUpperCase())?.boardLabel ?? ''
    },
    [findSecurityBySymbol]
  )

  const fetchDetailsForSymbols = useCallback((symbols: string[]) => {
    dispatch(fetchSymbolDetails(symbols))
  }, [])

  const [, cancel] = useDebounce(
    () => {
      dispatch(globalSearch(searchString.toUpperCase()))
    },
    300,
    [searchString]
  )

  useEffect(() => {
    return () => {
      cancel()
      dispatch(clearGlobalSearch())
    }
  }, [])

  const searchResults = useMemo(() => {
    return rawSearchResults
      .reduce((prev, current) => {
        addUnique(prev, current.isin)
        addUnique(prev, current.cusip)
        addUnique(prev, current.issuerSymbol)
        return prev
      }, [] as string[])
      .filter(
        (res) =>
          res?.startsWith(searchString.toUpperCase()) &&
          (!omitIssuers || /\d/.test(res))
      )
      .sort()
  }, [rawSearchResults, searchString])

  return {
    fetchDetailsForSymbols,
    getBoardLabelForSymbol,
    searchResults
  }
}
