import { createSelector } from 'reselect'
import { namespace } from '.'
import config from '../../config'
import { parseJwt } from '../../helpers/auth'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

const FAKE_TOKEN_PREFIX = 'FAKE_TOKEN:'

export const getAuthToken = createSelector([getState], (state) => {
  if (config.okta.deactivate) {
    return `${FAKE_TOKEN_PREFIX}${config.okta.userId}`
  }
  return state.authToken
})

export const getParsedAuthToken = createSelector(
  [getAuthToken],
  (token) =>
    (token && !token.startsWith(FAKE_TOKEN_PREFIX) && parseJwt(token)) || {}
)

export const getUserIdFromToken = createSelector(
  [getParsedAuthToken],
  (parsedToken) => Number(parsedToken.userId)
)

export const authTokenPending = createSelector(
  [getState],
  (state) => state.authTokenPending
)

export const authTokenError = createSelector(
  [getState],
  (state) => state.authTokenError
)

export const getUserName = createSelector([getState], (state) => state.userName)

export const getUserId = createSelector([getState], (state) => state.userId)

export const getIsSignedIn = createSelector([getAuthToken], Boolean)

export const getOktaAuth = createSelector([getState], (state) => state.oktaAuth)

export const getAuthType = createSelector([getState], (state) => state.authType)

export const getIsInitialized = createSelector(
  [getState],
  (state) => state.initialized
)
