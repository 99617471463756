import cx from 'classnames'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  faChevronDoubleLeft,
  faChevronDoubleRight
} from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { toggleActivityPanel } from '../../store/activity/actions'
import {
  getActivitiesGroups,
  getActivityPanelVisible
} from '../../store/activity/selectors'
import { Activity } from '../../store/activity/types'
import {
  subscribeToAlerts,
  unsubscribeFromAlerts
} from '../../store/alerts/actions'

import ExpandableComponent from '../ExpandableComponent/ExpandableComponent'
import ExpandableSectionTitle from '../ExpandableComponent/ExpandableSectionTitle'

// import { getAcceptedOrders } from '../../store/order/selectors'
import { setWindowFront } from '../../store/windows/actions'
import { getWindowFront } from '../../store/windows/selectors'
import ActivityCard from './ActivityCard'
import ActivityHeader from './ActivityHeader'
import styles from './ActivityWrapper.module.scss'
import TradeConfirm from './TradeConfirm/TradeConfirm'

const ActivityWrapper: FC = () => {
  const [initialRender, setInitialRender] = useState(true)
  const [tradeConfirmId, setTradeConfirmId] = useState<number | null>(null)
  // const [filteredTrades, setFilteredTrades] = useState<Activity[]>()

  const activitiesByGroup = useSelector(getActivitiesGroups)
  const activityPanelVisible = useSelector(getActivityPanelVisible)
  const windowFront = useSelector(getWindowFront)
  // const acceptedOrders = useSelector(getAcceptedOrders)
  // const tradesToday = activitiesByGroup.today.filter(
  //   (activity) => activity.type === 'trade'
  // )

  const dispatch = useDispatch()

  const hasActivities =
    activitiesByGroup.older.length !== 0 ||
    activitiesByGroup.pending.length !== 0 ||
    activitiesByGroup.today.length !== 0 ||
    activitiesByGroup.yesterday.length !== 0 ||
    activitiesByGroup.twoDaysAgo.length !== 0

  useEffect(() => {
    if (initialRender && hasActivities) {
      dispatch(toggleActivityPanel())
    }
  }, [hasActivities])

  useEffect(() => {
    dispatch(subscribeToAlerts())
    return () => {
      dispatch(unsubscribeFromAlerts())
    }
  }, [])

  const expandPanel = useCallback(() => {
    if (activityPanelVisible) {
      return false
    } else {
      dispatch(toggleActivityPanel())
      setInitialRender(false)
    }
  }, [activityPanelVisible])

  const collapsePanel = useCallback(() => {
    if (!activityPanelVisible) {
      return false
    } else {
      dispatch(toggleActivityPanel())
      setInitialRender(false)
    }
  }, [activityPanelVisible])

  const moveWindowToFront = useCallback(() => {
    dispatch(setWindowFront('activity'))
  }, [])

  useEffect(() => {
    activityPanelVisible && moveWindowToFront()
  }, [activityPanelVisible])

  const handleTradeConfirmClick = useCallback((id: string | null) => {
    setTradeConfirmId(id === null ? id : Number(id.replace('O', '')))
  }, [])

  const expandableTitle =
    (title: string) =>
    (isExpanded: boolean, setExpanded: (expanded: boolean) => void) => {
      return (
        <ExpandableSectionTitle
          className={styles.title}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
        >
          {title}
        </ExpandableSectionTitle>
      )
    }

  const renderActivityList = (activities: Activity[], placeholder: string) => {
    if (activities.length === 0) {
      return <p className={styles.placeholder}>{placeholder}</p>
    }
    return (
      <ul>
        {activities.map((activity) => (
          <li key={`${activity.type}-${activity.id}`}>
            <ActivityCard
              activity={activity}
              handleTradeConfirmClick={handleTradeConfirmClick}
            />
          </li>
        ))}
      </ul>
    )
  }

  // TODO: commenting out until a fix is added to accomodate for very active activity sections
  // const checkTradeTimes = () => {
  //   const currentTime = new Date().getTime()
  //   const trades = tradesToday.filter((trade) => {
  //     const tradeTime = trade.date.getTime()
  //     const secondsOut = (currentTime - tradeTime) / 1000
  //     // TODO: why there is there a 2 min difference between time in activity tab and actual time?
  //     // compensating here with 130 seconds
  //     if (trade.isFirm && secondsOut < 130) {
  //       return trade
  //     }
  //   })

  //   setFilteredTrades(trades)
  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     checkTradeTimes()
  //   }, 1000)
  //   return () => clearInterval(interval)
  // }, [acceptedOrders, tradesToday])

  return (
    <div
      className={cx(activityPanelVisible || styles.hidden)}
      onClick={expandPanel}
    >
      <div
        className={cx(
          styles.activityWrapper,
          activityPanelVisible || styles.hidden,
          windowFront === 'depth' || styles.windowFront
        )}
        onClick={moveWindowToFront}
      >
        <div className={styles.activityWrapperHeader}>
          {activityPanelVisible && (
            <FontAwesomeIcon
              icon={faChevronDoubleRight}
              onClick={collapsePanel}
              title="Hide Activity Panel"
            />
          )}
          {!activityPanelVisible && (
            <FontAwesomeIcon
              icon={faChevronDoubleLeft}
              title="Show Activity Panel"
            />
          )}
        </div>

        <div className={styles.activityWrapperContent}>
          <ActivityHeader />
          {/* {filteredTrades && filteredTrades.length > 0 && (
              <div>
                <h2 className={styles.title}>Recent Trade</h2>
                <div className={styles.activityListContent}>
                  <div className={styles.blink}>
                    {renderActivityList(filteredTrades, '')}
                  </div>
                </div>
              </div>
            )} */}

          <div className={styles.activityList}>
            <h2 className={styles.title}>Pending trades</h2>
            <div className={styles.activityListContent}>
              {renderActivityList(
                activitiesByGroup.pending,
                'No pending trades.'
              )}
            </div>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Today')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.today,
                  'No activity for today.'
                )}
              </div>
            </ExpandableComponent>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Yesterday')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.yesterday,
                  'No activity for yesterday.'
                )}
              </div>
            </ExpandableComponent>
          </div>

          <div className={styles.activityList}>
            <ExpandableComponent title={expandableTitle('Two days ago')}>
              <div className={styles.activityListContent}>
                {renderActivityList(
                  activitiesByGroup.twoDaysAgo,
                  'No activity for two days ago.'
                )}
              </div>
            </ExpandableComponent>
          </div>
        </div>
        {tradeConfirmId && (
          <TradeConfirm
            tradeConfirmId={tradeConfirmId}
            handleTradeConfirmClick={handleTradeConfirmClick}
          />
        )}
      </div>
    </div>
  )
}

export default memo(ActivityWrapper)
