import React, { FC } from 'react'
import { useOpenFin } from '../../../app/openFinContext'
import AdminMenu from './AdminMenu'
import ClientMenu from './ClientMenu'
import styles from './menus.module.scss'
import UserMenu from './UserMenu'

export interface Props {
  menuName: string
  closeMenu: () => void
}

const LeftMenu: FC<Props> = ({ menuName, closeMenu }) => {
  const { fin, renderWindowCheck } = useOpenFin()
  const getMenu = () => {
    switch (menuName) {
      case 'Admin':
        return (
          <AdminMenu
            fin={!!fin}
            closeMenu={closeMenu}
            handleNavigation={renderWindowCheck}
          />
        )
      case 'Client':
        return (
          <ClientMenu
            fin={!!fin}
            closeMenu={closeMenu}
            handleNavigation={renderWindowCheck}
          />
        )
      case 'User':
        return <UserMenu fin={!!fin} closeMenu={closeMenu} />
      default:
        return null
    }
  }
  return <div className={styles.menuWrapper}>{getMenu()}</div>
}

export default LeftMenu
