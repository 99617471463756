import React from 'react'
import { Outlet } from 'react-router-dom'
import Loading from '../components/Loading/Loading'
import { needsWSConnection } from '../helpers/hoc'
import { withAuthenticationRequired } from 'react-oidc-context'

const OidcProtectedRouteParent = () => {
  return <Outlet />
}

const WsConnectedParent = needsWSConnection(OidcProtectedRouteParent)

export default withAuthenticationRequired(WsConnectedParent, {
  OnRedirecting: () => <Loading />
})
