import React, { useCallback, useEffect, useMemo } from 'react'
import { AuthProvider, AuthProviderProps, useAuth } from 'react-oidc-context'
import Loading from '../components/Loading/Loading'
import { parseJwt } from '../helpers/auth'
import {
  fetchAuthToken,
  fetchAuthTokenFailure,
  fetchAuthTokenSuccess,
  redirectToLogin
} from '../store/auth/actions'
import {
  authTokenError,
  authTokenPending,
  getAuthToken,
  getUserName
} from '../store/auth/selectors'
import { addLogItem } from '../store/log/actions'
import { useAppDispatch, useAppSelector } from '../store/types'
import OidcProtectedRouteParent from './OidcProtectedRouteParent'
import { SessionContext } from './SessionContext'
import App from '../app/App'

import styles from './styles.module.css'

// modeled off Okta Security
const OidcSecurity = () => {
  // automaticSilentRenew on by default in UserManagerSettings oidc-client-ts,
  // so we just store the new token as it comes in
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const storedToken = useAppSelector(getAuthToken)
  const tokenPending = useAppSelector(authTokenPending)
  const authError = useAppSelector(authTokenError)
  const userName = useAppSelector(getUserName)

  useEffect(() => {
    if (!storedToken && !tokenPending) {
      // doesn't really fetch anything in keycloak, but does turn on the keycloak code
      dispatch(fetchAuthToken('Keycloak'))
    }
  }, [storedToken, tokenPending])

  useEffect(() => {
    if (auth.isAuthenticated) {
      const token = auth.user?.access_token
      if (token && token !== storedToken) {
        const user = parseJwt(token)
        dispatch(fetchAuthTokenSuccess(token, user.name ?? '', user?.sid ?? ''))
      }
    } else if (!auth.isLoading) {
      auth.signinRedirect()
    } else if (auth.error) {
      dispatch(fetchAuthTokenFailure(auth.error))
    }
  }, [auth.isAuthenticated, auth.error, auth.user?.access_token, storedToken])

  const logout = useCallback(() => {
    dispatch(addLogItem('logging out user ' + userName))
    dispatch(redirectToLogin())
    auth.signoutRedirect()
  }, [auth, userName])
  const editProfile = useCallback(() => {
    window.open(`${auth.settings.authority}/account`, 'editProfile')
  }, [auth.settings.authority])

  const sessionContextProps = useMemo(() => {
    return { signout: logout, editProfile } as const
  }, [logout, editProfile])

  if (auth.isLoading) {
    return <Loading />
  }

  // comments in react-oidc-context suggest not calling signoutRedirect from useEffect
  if (authError) {
    return (
      <div className={styles.centered}>
        <p>
          You have encountered a temporary authentication error. Please log out
          and retry.
        </p>
        <button data-testid="oidc-fail-signout" onClick={logout}>
          Sign out
        </button>
      </div>
    )
  }
  return (
    <SessionContext.Provider value={sessionContextProps}>
      <App SecureRoute={OidcProtectedRouteParent} />
    </SessionContext.Provider>
  )
}
const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname)
}
const OidcWrapper = (props: AuthProviderProps) => {
  return (
    <AuthProvider onSigninCallback={onSigninCallback} {...props}>
      <OidcSecurity />
    </AuthProvider>
  )
}

export default OidcWrapper
