import { OrderRowInfo } from '../upload/types'
import { Security } from '../securities/reducer'
import { StagedOrder } from '../stagedOrders/types'
import { SecuritySearchResult } from './types'

type ServerSecuritySearchResult = Omit<SecuritySearchResult, 'maturityDate'> & {
  maturityDate: string | Date
}
export const createSecuritySearchResultFromResponse = <
  T extends ServerSecuritySearchResult
>(
  securityResponse: T
): SecuritySearchResult => ({
  ...securityResponse,
  maturityDate: new Date(securityResponse.maturityDate)
})

export const createResponseFromSecuritySearchResult = (
  security: SecuritySearchResult
) => ({
  ...security,
  maturityDate: security.maturityDate.toISOString()
})

export const createStagedOrdersFromSecuritySearch = (
  securityIds: Array<Security['id']>,
  otherInfo: Omit<OrderRowInfo, 'identifier'>,
  bookId: number
): StagedOrder[] | [] => {
  const stagedOrders: StagedOrder[] = []

  if (securityIds.length === 1) {
    let stagedOrder = {
      securityId: securityIds[0]
    } as StagedOrder

    if (otherInfo.bidAmt && otherInfo.bidPrice) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'buy',
        price: Number(otherInfo.bidPrice),
        size: Number(otherInfo.bidAmt),
        allOrNone: otherInfo.bidAon ? otherInfo.bidAon : false,
        isSpreadOrder: false,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.bidAmt && otherInfo.bidSprd) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'buy',
        spread: Number(otherInfo.bidSprd),
        size: Number(otherInfo.bidAmt),
        allOrNone: otherInfo.bidAon ? otherInfo.bidAon : false,
        isSpreadOrder: true,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.bidPrice && Number(otherInfo.bidAmt) === 0) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'buy',
        price: Number(otherInfo.bidPrice),
        size: 100,
        allOrNone: false,
        isSpreadOrder: false,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.bidSprd && Number(otherInfo.bidAmt) === 0) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'buy',
        spread: Number(otherInfo.bidSprd),
        size: 100,
        allOrNone: false,
        isSpreadOrder: true,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    }

    if (otherInfo.ofrAmt && otherInfo.ofrPrice) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'sell',
        price: Number(otherInfo.ofrPrice),
        size: Number(otherInfo.ofrAmt),
        allOrNone: otherInfo.bidAon ? otherInfo.bidAon : false,
        isSpreadOrder: false,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.ofrAmt && otherInfo.ofrSprd) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'sell',
        spread: Number(otherInfo.ofrSprd),
        size: Number(otherInfo.ofrAmt),
        allOrNone: otherInfo.bidAon ? otherInfo.bidAon : false,
        isSpreadOrder: true,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.ofrPrice && Number(otherInfo.ofrAmt) === 0) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'sell',
        price: Number(otherInfo.ofrPrice),
        size: 100,
        allOrNone: false,
        isSpreadOrder: false,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    } else if (otherInfo.ofrSprd && Number(otherInfo.ofrAmt) === 0) {
      stagedOrder = {
        ...stagedOrder,
        orderType: 'sell',
        spread: Number(otherInfo.ofrSprd),
        size: 100,
        allOrNone: false,
        isSpreadOrder: true,
        custId: bookId
      }
      stagedOrders.push(stagedOrder)
    }
  }

  return stagedOrders
}
