import { useCallback } from 'react'
import { createOrderModel } from '../../components/DepthOfMarket/helpers'
import { OrderEntryModel } from '../../store/depthOfMarket/types'
import { addLogItem } from '../../store/log/actions'
import { cancelOrder, createOrder } from '../../store/order/actions'
import { orderModelToParams } from '../../store/order/helpers'
import {
  getErrorForOrder,
  getPendingUserOrderForSecurity
} from '../../store/order/selectors'
import { OrderType } from '../../store/order/types'
import { Security } from '../../store/securities/reducer'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder,
  setFocusOnOrder,
  setTempOrderFieldValue,
  updateWorkingOrderField
} from '../../store/stagedOrders/actions'
import { workingOrderMiniReducer } from '../../store/stagedOrders/helpers'
import {
  getStagedOrderBySecurityId,
  getTempFieldValuesForOrder,
  getWorkingOrder,
  orderIsFocused
} from '../../store/stagedOrders/selectors'
import { StagedOrder } from '../../store/stagedOrders/types'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getDefaultBidOfferValue } from '../../store/userPreferences/selectors'
import { formatSize } from '../formatting'
import { calculateOrderPriceFromInput } from '../utils'

type NewLiveOrder = Omit<StagedOrder, 'orderType'> & {
  id: string
}
type NewStagedOrder = Omit<StagedOrder, 'orderType'>

export type OrderFields = 'price' | 'size' | 'spread'

// actions
const createOrderLog = (
  order: NewLiveOrder | NewStagedOrder,
  securityId: number,
  type: OrderType
) => {
  return `create order on security ${securityId} price: ${order.price}, isSpread: ${order.isSpreadOrder} size: ${order.size}, side: ${type}, aon: ${order.allOrNone}`
}
const createOrderAction = (
  order: NewLiveOrder | NewStagedOrder,
  securityId: number,
  type: OrderType
) => {
  // I don't think it matters if the security is a treasury for this application
  const model: OrderEntryModel = createOrderModel(order, type, false)
  return createOrder(securityId, model)
}

// util
const isEmptyOrder = (order: NewLiveOrder | NewStagedOrder) => {
  return !order.price && !order.spread
}
const createNewStagedOrder = (
  securityId: number,
  isSpreadOrder: boolean,
  defaultSize: number
) => {
  return {
    securityId,
    price: 0,
    spread: 0,
    isSpreadOrder,
    size: defaultSize,
    allOrNone: false,
    individualMin: 0,
    custId: 0,
    tob: { tob: false, limitPrice: 0, floorPrice: 0 }
  }
}

const calculateActualOrImpliedPendingStyle = (
  fieldName: OrderFields,
  isSpread: boolean
) => {
  // true is actual (apply style), false is implied (no style)
  switch (fieldName) {
    case 'size':
      return true
    case 'spread':
      return isSpread
    case 'price':
      return !isSpread
  }
}

const orderTypeMapping = {
  buy: {
    name: 'bid',
    action: 'HIT'
  },
  sell: {
    name: 'offer',
    action: 'LIFT'
  }
} as const

export const useManageOneOrderType = (orderType: OrderType) => {
  const dispatch = useAppDispatch()

  // TODO: switch to getStagedOrderBySecurityForOrderType
  // (also check for other selectors that let you zero in on order type)
  const getStagedOrder = useAppSelector(getStagedOrderBySecurityId)
  const getLiveOrder = useAppSelector(getPendingUserOrderForSecurity)
  const getOrderValidation = useAppSelector(getErrorForOrder)
  const getTempFieldValues = useAppSelector(getTempFieldValuesForOrder)

  const defaultBidOffer = useAppSelector(getDefaultBidOfferValue)
  const getSecurityOrderIsFocused = useAppSelector(orderIsFocused)

  const workingOrder = useAppSelector(getWorkingOrder)

  const orderTypeName = orderTypeMapping[orderType].name
  const orderTypeAction = orderTypeMapping[orderType].action

  const focusOrder = useCallback((securityId: number) => {
    dispatch(setFocusOnOrder({ securityId, orderType }))
  }, [])

  const getStagedAndLiveOrderForSecurity = useCallback(
    (securityId: number) => {
      const stagedOrder =
        (securityId && getStagedOrder(securityId)?.[orderType]) || undefined
      const liveOrder =
        (securityId && getLiveOrder(securityId, orderType)) || undefined

      return { liveOrder, stagedOrder }
    },
    [getStagedOrder, getLiveOrder]
  )
  const getStagedOrLiveOrderForSecurity = useCallback(
    (securityId: number) => {
      const { stagedOrder, liveOrder } =
        getStagedAndLiveOrderForSecurity(securityId)

      return liveOrder || stagedOrder
    },
    [getStagedAndLiveOrderForSecurity]
  )

  const getStyleConditions = useCallback(
    (securityId: number, field: OrderFields) => {
      const conditions = {
        hasError: false,
        isPending: false,
        hasFocus: false
      }
      if (!securityId) return conditions
      if (getOrderValidation(securityId, orderType) !== undefined) {
        conditions.hasError = true
      }
      const { liveOrder } = getStagedAndLiveOrderForSecurity(securityId)
      if (liveOrder) {
        conditions.isPending =
          !!liveOrder &&
          calculateActualOrImpliedPendingStyle(field, liveOrder.isSpreadOrder)
      }
      const isFocused = getSecurityOrderIsFocused(securityId, orderType)
      if (isFocused) {
        conditions.hasFocus = true
      }
      return conditions
    },
    [
      getStagedAndLiveOrderForSecurity,
      getOrderValidation,
      getSecurityOrderIsFocused
    ]
  )

  const updateLiveAndStagedOrder = useCallback(
    (
      cellValue: string,
      stagedOrder: NewStagedOrder,
      liveOrder?: NewLiveOrder
    ) => {
      if (cellValue === '') {
        if (liveOrder) {
          dispatch(cancelOrder(liveOrder.id))
        }
        if (stagedOrder && isEmptyOrder(stagedOrder)) {
          dispatch(
            removeStagedOrder({ securityId: stagedOrder.securityId, orderType })
          )
        }
        return
      }
      if (liveOrder) {
        dispatch(
          addLogItem(
            createOrderLog(liveOrder, stagedOrder.securityId, orderType)
          )
        )
        dispatch(
          createOrderAction(liveOrder, stagedOrder.securityId, orderType)
        )
      }
      dispatch(addOrUpdateStagedOrders([{ ...stagedOrder, orderType }]))
    },
    []
  )

  const buildNewOrderParamsForSecurity = useCallback(
    (security: Security, isSpreadOrder: boolean) => {
      const { liveOrder: oldLiveOrder, stagedOrder: oldStagedOrder } =
        getStagedAndLiveOrderForSecurity(security.id)
      const newLiveOrder = oldLiveOrder
        ? {
            securityId: security.id,
            price: oldLiveOrder.price,
            spread: oldLiveOrder.spread,
            isSpreadOrder: oldLiveOrder.isSpreadOrder,
            size: oldLiveOrder.size,
            allOrNone: oldLiveOrder.allOrNone,
            individualMin: oldLiveOrder.individualMin || 0,
            custId: oldLiveOrder.custId,
            tob: oldLiveOrder.tob,
            id: oldLiveOrder.id
          }
        : undefined
      const newStagedOrder = oldStagedOrder
        ? { ...oldStagedOrder, securityId: security.id }
        : undefined
      const defaultSize = defaultBidOffer ?? security.defaultOrderSize ?? 100
      return {
        liveOrder: newLiveOrder,
        stagedOrder:
          newStagedOrder ??
          createNewStagedOrder(security.id, isSpreadOrder, defaultSize)
      }
    },
    [getStagedAndLiveOrderForSecurity, defaultBidOffer]
  )
  const getTempFieldValuesForStagedOrder = useCallback(
    (securityId: Security['id']) => {
      return getTempFieldValues(securityId, orderType)
    },
    [getTempFieldValues]
  )

  const getInitialFieldValueForSecurity = useCallback(
    (securityId: number, fieldName: OrderFields) => {
      /*  TODO: we can simplify this logic by using workingOrder,
          but the MyOrderEditor focuses the order after rendering,
          creating a race condition on setting the initial value.
          Revisit later.
       */
      const uncommittedFieldValues =
        getTempFieldValuesForStagedOrder(securityId)
      const existingOrder = getStagedOrLiveOrderForSecurity(securityId)
      const storedValue =
        existingOrder?.[fieldName] || uncommittedFieldValues?.[fieldName] || ''

      if (fieldName === 'price') {
        return existingOrder && 'displayPrice' in existingOrder
          ? existingOrder.displayPrice
          : storedValue
      }

      if (fieldName !== 'size' || !existingOrder) {
        return storedValue
      }
      const aon = existingOrder.allOrNone ? 'a' : ''
      if (existingOrder) {
        const { totalSize, size } = existingOrder
        if (totalSize && size && totalSize !== size) {
          return formatSize(size, totalSize, !!aon, true)
        }
        return existingOrder.totalSize + aon
      }
      return storedValue + aon
    },
    [getTempFieldValuesForStagedOrder, getStagedOrLiveOrderForSecurity]
  )

  const getTempOrDefaultSize = useCallback(
    (security: Security) => {
      const tempFieldValues = getTempFieldValues(security.id, orderType)
      if (tempFieldValues?.size) {
        return parseInt(tempFieldValues.size, 10)
      }
      return defaultBidOffer ?? security.defaultOrderSize ?? 100
    },
    [getTempFieldValues, defaultBidOffer]
  )

  const setOrderSize = useCallback(
    (size: string, security?: Security) => {
      // check if there are any updates to make
      if (!security || !workingOrder) return

      const updateAction = updateWorkingOrderField('size', size)
      dispatch(updateAction)

      // use the next expected state to update orders
      // TODO: do the updates in the reducer based on dispatching the action
      const nextWorkingOrder = workingOrderMiniReducer(
        workingOrder,
        updateAction
      )
      if (
        nextWorkingOrder.totalSize === workingOrder.totalSize &&
        nextWorkingOrder.displaySize === workingOrder.displaySize &&
        nextWorkingOrder.aon === workingOrder.aon
      ) {
        return
      }
      // const orderUpdates = calculateSizeAndAonFromInput(size || '')
      const { totalSize, clientSize } = orderModelToParams(nextWorkingOrder)
      const orderUpdates = {
        size: clientSize,
        totalSize,
        allOrNone: nextWorkingOrder.aon
      }
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      if (!oldOrder) {
        // you can't make an order just based on size
        dispatch(setTempOrderFieldValue(security.id, orderType, 'size', size))
        return
      }

      // get live and staged orders to work with
      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )
      const newLiveOrder = liveOrder
        ? { ...liveOrder, ...orderUpdates }
        : undefined

      // make the update
      updateLiveAndStagedOrder(
        size,
        { ...stagedOrder, ...orderUpdates },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getStagedOrLiveOrderForSecurity,
      workingOrder
    ]
  )

  const setOrderPrice = useCallback(
    (price: string, security?: Security) => {
      if (!security || !workingOrder) return
      const newPrice = calculateOrderPriceFromInput(
        price,
        security.product === 'PrinUSGovtOutright'
      )
      const defaultSize = getTempOrDefaultSize(security)
      const updateAction = updateWorkingOrderField(
        'price',
        `${newPrice}`,
        false,
        defaultSize
      )
      dispatch(updateAction)

      const nextWorkingOrder = workingOrderMiniReducer(
        workingOrder,
        updateAction
      )

      if (
        workingOrder.amount === nextWorkingOrder.amount &&
        workingOrder.amountType === nextWorkingOrder.amountType
      ) {
        return
      }

      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )

      const {
        spread: newSpread,
        isSpreadOrder,
        clientSize: size = defaultSize,
        totalSize
      } = orderModelToParams(nextWorkingOrder)

      const newLiveOrder = liveOrder
        ? {
            ...liveOrder,
            price: newPrice,
            spread: newSpread,
            isSpreadOrder,
            size,
            allOrNone: nextWorkingOrder.aon,
            totalSize
          }
        : undefined
      updateLiveAndStagedOrder(
        price,
        {
          ...stagedOrder,
          price: newPrice,
          spread: newSpread,
          isSpreadOrder,
          size,
          allOrNone: nextWorkingOrder.aon,
          totalSize
        },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getTempOrDefaultSize,
      workingOrder
    ]
  )

  const setOrderSpread = useCallback(
    (spread: string, security?: Security) => {
      if (!security || !workingOrder) return
      const newSpread = parseFloat(spread)

      const defaultSize = getTempOrDefaultSize(security)
      const updateAction = updateWorkingOrderField(
        'spread',
        spread,
        false,
        defaultSize
      )
      dispatch(updateAction)

      const nextWorkingOrder = workingOrderMiniReducer(
        workingOrder,
        updateAction
      )

      if (
        workingOrder.amount === nextWorkingOrder.amount &&
        workingOrder.amountType === nextWorkingOrder.amountType
      ) {
        return
      }

      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )

      const {
        clientSize: size = defaultSize,
        actualPrice: price,
        isSpreadOrder,
        totalSize
      } = orderModelToParams(nextWorkingOrder)

      const updates = {
        spread: newSpread || undefined,
        isSpreadOrder,
        price,
        size,
        allOrNone: nextWorkingOrder.aon,
        totalSize
      }

      const newLiveOrder = liveOrder
        ? {
            ...liveOrder,
            ...updates
          }
        : undefined
      updateLiveAndStagedOrder(
        spread,
        {
          ...stagedOrder,
          ...updates
        },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getTempOrDefaultSize,
      workingOrder
    ]
  )

  const setOrderFieldByName = useCallback(
    (fieldName: OrderFields, fieldValue: string, security?: Security) => {
      switch (fieldName) {
        case 'spread': {
          setOrderSpread(fieldValue, security)
          break
        }
        case 'price': {
          setOrderPrice(fieldValue, security)
          break
        }
        case 'size': {
          setOrderSize(fieldValue, security)
        }
      }
    },
    [setOrderSpread, setOrderPrice, setOrderSize]
  )

  const canEditThisField = useCallback(
    (fieldName: OrderFields, securityId: number) => {
      const storedOrder = getStagedOrLiveOrderForSecurity(securityId)
      // can always edit staged orders
      if (!storedOrder?.hasOwnProperty('id')) {
        return true
      }
      const isTob = !!storedOrder?.tob?.tob
      switch (fieldName) {
        case 'size':
          return (
            !storedOrder?.totalSize ||
            storedOrder.totalSize === storedOrder.size
          )
        case 'spread':
          return !isTob && Boolean(storedOrder && storedOrder.isSpreadOrder)
        case 'price':
          return !isTob && Boolean(storedOrder && !storedOrder?.isSpreadOrder)
      }
    },
    [getStagedOrLiveOrderForSecurity]
  )

  const submitStagedOrder = useCallback(
    (security: Security) => {
      // not using workingOrder bc we could be submitting different order
      const securityId = security.id
      const oldStagedOrder =
        securityId && getStagedOrder(securityId)?.[orderType]
      if (!oldStagedOrder) return false
      if (getOrderValidation(securityId, orderType) !== undefined) return false
      const { stagedOrder } = buildNewOrderParamsForSecurity(security, false)
      dispatch(createOrderAction(stagedOrder, securityId, orderType))
      return true
    },
    [getStagedOrder, getOrderValidation]
  )

  const stageAndSubmitOrder = useCallback(
    (fieldName: OrderFields, fieldValue: string, security?: Security) => {
      if (!security || !workingOrder) return
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      if (!oldOrder && fieldName === 'size') return

      const defaultSize = defaultBidOffer ?? security.defaultOrderSize
      const updateAction = updateWorkingOrderField(
        fieldName,
        fieldValue,
        false,
        defaultSize
      )
      dispatch(updateAction) // not sure we need this, but it can't hurt

      const nextWorkingOrder = workingOrderMiniReducer(
        workingOrder,
        updateAction
      )
      dispatch(createOrder(security.id, nextWorkingOrder))
    },
    [getStagedOrder, workingOrder]
  )

  return {
    focusOrder,
    getTempFieldValuesForStagedOrder,
    getStagedOrLiveOrderForSecurity,
    getStyleConditions,
    getInitialFieldValueForSecurity,

    orderType,
    orderTypeAction,
    orderTypeName,

    setOrderFieldByName,
    canEditThisField,

    setOrderPrice,
    setOrderSize,
    setOrderSpread,

    stageAndSubmitOrder,
    submitStagedOrder
  }
}

export type OrderManager = ReturnType<typeof useManageOneOrderType>
