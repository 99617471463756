import { Action } from 'redux'
import { Order } from '../order/types'
import { QuoteReliability } from '../securities/types'

export interface SelectSecurityAction extends Action {
  type: 'depthofmarket.selectSecurity'
  payload: {
    gridIndex: number
    securityId: number
    quoteReliability: QuoteReliability
  }
}

export const selectSecurity = (
  gridIndex: number,
  securityId: number,
  quoteReliability: QuoteReliability
): SelectSecurityAction => ({
  type: 'depthofmarket.selectSecurity',
  payload: { gridIndex, securityId, quoteReliability }
})

export interface PopoutSecurityAction extends Action {
  type: 'depthofmarket.popout'
  payload: number
}

export const popoutSecurity = (payload: number): PopoutSecurityAction => ({
  type: 'depthofmarket.popout',
  payload
})

export interface FetchDepthAction extends Action {
  type: 'depthofmarket.fetchDepth'
}

export const fetchDepth = (): FetchDepthAction => ({
  type: 'depthofmarket.fetchDepth'
})

export interface NewSubscriptionAction extends Action {
  type: 'depthofmarket.newSubscription'
  payload: {
    gridIndex: number
    securityId: number
    quoteReliability: QuoteReliability
    popout: boolean
  }
}

export const newSubscription = (
  gridIndex: number,
  securityId: number,
  quoteReliability: QuoteReliability,
  popout: boolean
): NewSubscriptionAction => ({
  type: 'depthofmarket.newSubscription',
  payload: { gridIndex, securityId, quoteReliability, popout }
})

export interface RemoveSubscriptionAction extends Action {
  type: 'depthofmarket.removeSubscription'
  payload: { gridIndex: number; securityId: number; popout: boolean }
}

export const removeSubscription = (
  gridIndex: number,
  securityId: number,
  popout: boolean
): RemoveSubscriptionAction => ({
  type: 'depthofmarket.removeSubscription',
  payload: { gridIndex, securityId, popout }
})

export interface LoadPopoutAction extends Action {
  type: 'depthofmarket.loadPopout'
  payload: Order[]
}

export const loadPopout = (payload: Order[]): LoadPopoutAction => ({
  type: 'depthofmarket.loadPopout',
  payload
})

export interface ClosePopoutAction extends Action {
  type: 'depthofmarket.closePopout'
  payload: number
}

export const closePopout = (payload: number): ClosePopoutAction => ({
  type: 'depthofmarket.closePopout',
  payload
})

export interface CloseAllPopoutsAction extends Action {
  type: 'depthofmarket.closeAllPopouts'
}

export const closeAllPopouts = (): CloseAllPopoutsAction => ({
  type: 'depthofmarket.closeAllPopouts'
})

export interface UpdatePopoutsAction extends Action {
  type: 'depthofmarket.updatePopouts'
  payload: {
    previous: number
    current: number
  }
}

export const updatePopouts = (
  previous: number,
  current: number
): UpdatePopoutsAction => ({
  type: 'depthofmarket.updatePopouts',
  payload: { previous, current }
})

export interface LoadSecurityFailureAction extends Action {
  type: 'depthofmarket.loadSecurityFailure'
  payload: any
  error: true
  securityId: number
}

export const loadSecurityFailure = (
  error: any,
  securityId: number
): LoadSecurityFailureAction => ({
  type: 'depthofmarket.loadSecurityFailure',
  payload: error,
  error: true,
  securityId
})

export interface AddOrUpdateDepthOfMarketAction extends Action {
  type: 'order.addOrUpdateDepthOfMarket'
  payload: {
    gridIndex: number
    securityId: number
    orders: Order[]
    hoveredOrders: Order[]
  }
}

export const addOrUpdateDepthOfMarket = (
  gridIndex: number,
  securityId: number,
  orders: Order[],
  hoveredOrders: Order[]
): AddOrUpdateDepthOfMarketAction => ({
  type: 'order.addOrUpdateDepthOfMarket',
  payload: { gridIndex, securityId, orders, hoveredOrders }
})

export interface SetDepthOrdersAction extends Action {
  type: 'depthofmarket.setDepthOrders'
  payload: Record<number, Order[]>
}

export const setDepthOrders = (
  orders: Record<number, Order[]>
): SetDepthOrdersAction => ({
  type: 'depthofmarket.setDepthOrders',
  payload: orders
})

export interface ResetDOMForGridAction extends Action {
  type: 'depthofmarket.resetDOMForGrid'
  payload: { gridIndex: number }
}

export const resetDOMForGrid = (gridIndex: number): ResetDOMForGridAction => ({
  type: 'depthofmarket.resetDOMForGrid',
  payload: { gridIndex }
})

export interface MarkAsRestrictedAction extends Action {
  type: 'depthofmarket.markAsRestricted'
  payload: { id: string }
}

export const markAsRestricted = (id: string): MarkAsRestrictedAction => {
  return {
    type: 'depthofmarket.markAsRestricted',
    payload: { id }
  }
}
export interface MarkAsRestrictedSuccessAction extends Action {
  type: 'depthofmarket.markAsRestrictedSuccess'
  payload: { errors: string }
}

export const markAsRestrictedSuccess = (
  errors: any
): MarkAsRestrictedSuccessAction => ({
  type: 'depthofmarket.markAsRestrictedSuccess',
  payload: errors
})

export interface MarkAsRestrictedFailureAction extends Action {
  type: 'depthofmarket.markAsRestrictedFailure'
  payload: { errors: string }
}

export const markAsRestrictedFailure = (
  errors: any
): MarkAsRestrictedFailureAction => ({
  type: 'depthofmarket.markAsRestrictedFailure',
  payload: errors
})
export interface MarkAsUnrestrictedAction extends Action {
  type: 'depthofmarket.markAsUnrestricted'
  payload: { id: string }
}

export const markAsUnrestricted = (id: string): MarkAsUnrestrictedAction => {
  return {
    type: 'depthofmarket.markAsUnrestricted',
    payload: { id }
  }
}
export interface MarkAsUnrestrictedSuccessAction extends Action {
  type: 'depthofmarket.markAsUnrestrictedSuccess'
  payload: { errors: string }
}

export const markAsUnrestrictedSuccess = (
  errors: any
): MarkAsUnrestrictedSuccessAction => ({
  type: 'depthofmarket.markAsUnrestrictedSuccess',
  payload: errors
})
export interface MarkAsUnrestrictedFailureAction extends Action {
  type: 'depthofmarket.markAsUnrestrictedFailure'
  payload: { errors: string }
}

export const markAsUnrestrictedFailure = (
  errors: any
): MarkAsUnrestrictedFailureAction => ({
  type: 'depthofmarket.markAsUnrestrictedFailure',
  payload: errors
})
export interface ForceCancelAction extends Action {
  type: 'depthofmarket.forceCancel'
  payload: { id: string }
}

export const forceCancel = (id: string): ForceCancelAction => {
  return {
    type: 'depthofmarket.forceCancel',
    payload: { id }
  }
}

export interface ForceCancelSuccessAction extends Action {
  type: 'depthofmarket.forceCancelSuccess'
  payload: { errors: string }
}

export const forceCancelSuccess = (errors: any): ForceCancelSuccessAction => ({
  type: 'depthofmarket.forceCancelSuccess',
  payload: errors
})

export interface ForceCancelFailureAction extends Action {
  type: 'depthofmarket.forceCancelFailure'
  payload: { errors: string }
}

export const forceCancelFailure = (errors: any): ForceCancelFailureAction => ({
  type: 'depthofmarket.forceCancelFailure',
  payload: errors
})

export interface DOMLimitExceededAction extends Action {
  type: 'depthofmarket.domLimitExceeded'
  payload: boolean
}

export const domLimitExceeded = (
  overflow: boolean
): DOMLimitExceededAction => ({
  type: 'depthofmarket.domLimitExceeded',
  payload: overflow
})

export const setDomSecurityByQuery = (oldSecurityId: number, query: string) =>
  ({
    type: `depthofmarket.setDomSecurityByQuery`,
    payload: { oldSecurityId, query }
  } as const)
export type SetDomSecurityByQueryAction = ReturnType<
  typeof setDomSecurityByQuery
>

export type DepthOfMarketAction =
  | SelectSecurityAction
  | PopoutSecurityAction
  | LoadPopoutAction
  | ClosePopoutAction
  | CloseAllPopoutsAction
  | UpdatePopoutsAction
  | LoadSecurityFailureAction
  | AddOrUpdateDepthOfMarketAction
  | ResetDOMForGridAction
  | NewSubscriptionAction
  | RemoveSubscriptionAction
  | SetDepthOrdersAction
  | FetchDepthAction
  | MarkAsRestrictedAction
  | MarkAsRestrictedSuccessAction
  | MarkAsRestrictedFailureAction
  | MarkAsUnrestrictedAction
  | MarkAsUnrestrictedSuccessAction
  | MarkAsUnrestrictedFailureAction
  | ForceCancelAction
  | ForceCancelSuccessAction
  | ForceCancelFailureAction
  | DOMLimitExceededAction
  | SetDomSecurityByQueryAction
