import { Dispatch } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ActivityAction } from './activity/actions'
import { ActivityLogActions } from './activityLog/types'
import { TradeBlotterActions } from './activityTradeBlotter/types'
import { ActivityTickerAction } from './admin/activityTicker/types'
import { AdminTradeBlotterAction } from './admin/adminTradeBlotter/types'
import { AggressAttemptAction } from './admin/aggressAttempt/types'
import { BBMISINAction } from './admin/bbmisin/types'
import { ExternalLiquidityStatsAction } from './admin/externalLiquidityStats/types'
import { ExternalOrderStatsAction } from './admin/externalOrderStats/types'
import { HistoricalDataAction } from './admin/historicalUserData/types'
import { ManagementReportAction } from './admin/managementReports/types'
import { QueryAction } from './admin/query/actions'
import { QueueStatsAction } from './admin/queueStats/types'
import { RestrictedTradesAction } from './admin/restrictedTrades/types'
import { SystemParamAction } from './admin/sysparam/types'
import { TierAction } from './admin/tiers/types'
import { UserDashboardAction } from './admin/userDashboard/actions'
import { VenueAction } from './admin/venues/types'
import { AlertFilterAction } from './alertFilter/actions'
import { AlertsAction } from './alerts/actions'
import reducer from './appReducer'
import { AuthAction } from './auth/actions'
import { BenchmarkDataAction } from './benchmarkData/types'
import { BookAction } from './books/actions'
import { CheckedOrderAction } from './checkedOrders/actions'
import { CustomersAction } from './customers/types'
import { DepthOfMarketAction } from './depthOfMarket/actions'
import { GridAction } from './grid/actions'
import { HeartbeatAction } from './heartbeat/actions'
import { LastLookAction } from './lastLook/actions'
import { ListTradingAction } from './listTrading/actions'
import { LogAction } from './log/actions'
import { MarketAction } from './market/actions'
import {
  CreateOrderAction,
  CreateOrdersAction,
  OrderAction
} from './order/actions'
import { PassiveOrdersAction } from './passiveOrders/actions'
import { SearchSecuritiesActions } from './searchSecurities/actions'
import {
  FetchSecuritiesByIdsAction,
  SecuritiesAction,
  SetIsMine,
  SetMyBook,
  SetMyFirm,
  SetSizeFilter,
  SetUseAdvancedFilter
} from './securities/actions'
import { SettingsAction } from './settings/actions'
import { StagedOrdersAction } from './stagedOrders/actions'
import { ToastAction } from './toasts/actions'
import { UploadOrdersActions } from './upload/actions'
import { UserEditorDataAction } from './userEditorData/types'
import { UserPreferencesAction } from './userPreferences/actions'
import { UsersAction } from './users/types'
import {
  FetchBackgroundDetailsAction,
  WatchListsAction
} from './watchList/actions'
import { WebSettingsAction } from './webSettings/actions'
import { WindowsAction } from './windows/actions'
import { WsAction } from './ws/actions'

export type StoreAction =
  | ActivityAction
  | ActivityLogActions
  | ActivityTickerAction
  | AdminTradeBlotterAction
  | AggressAttemptAction
  | AlertsAction
  | AlertFilterAction
  | AuthAction
  | BBMISINAction
  | BenchmarkDataAction
  | BookAction
  | CheckedOrderAction
  | CustomersAction
  | DepthOfMarketAction
  | ExternalLiquidityStatsAction
  | ExternalOrderStatsAction
  | FetchBackgroundDetailsAction
  | FetchSecuritiesByIdsAction
  | GridAction
  | HeartbeatAction
  | HistoricalDataAction
  | LastLookAction
  | ListTradingAction
  | LogAction
  | ManagementReportAction
  | MarketAction
  | OrderAction
  | CreateOrdersAction
  | CreateOrderAction
  | PassiveOrdersAction
  | QueryAction
  | QueueStatsAction
  | RestrictedTradesAction
  | SearchSecuritiesActions
  | SecuritiesAction
  | SettingsAction
  | SetIsMine
  | SetMyBook
  | SetMyFirm
  | SetSizeFilter
  | SetUseAdvancedFilter
  | StagedOrdersAction
  | SystemParamAction
  | TierAction
  | ToastAction
  | TradeBlotterActions
  | UploadOrdersActions
  | UserDashboardAction
  | UserEditorDataAction
  | UserPreferencesAction
  | UsersAction
  | WatchListsAction
  | WebSettingsAction
  | WindowsAction
  | WsAction
  | VenueAction
export type State = ReturnType<typeof reducer>
export const useAppSelector: TypedUseSelectorHook<State> = useSelector
export const useAppDispatch: () => Dispatch<StoreAction> = useDispatch
