import { OktaAuthOAuthInterface } from '@okta/okta-auth-js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../config'
import { fetchAuthToken, setOktaAuth } from '../store/auth/actions'
import {
  authTokenError,
  authTokenPending,
  getAuthToken,
  getOktaAuth
} from '../store/auth/selectors'

let _auth: OktaAuthOAuthInterface
export { _auth as auth }

export const setAuth = (auth: OktaAuthOAuthInterface) => {
  _auth = auth
}

export const useToken = (auth: OktaAuthOAuthInterface) => {
  const authToken = useSelector(getAuthToken)
  const pending = useSelector(authTokenPending)
  const error = useSelector(authTokenError)
  const dispatch = useDispatch()

  // need to wait until this is set in Redux before epic can use it
  const oktaAuth = useSelector(getOktaAuth)

  useEffect(() => {
    if (auth) dispatch(setOktaAuth(auth))
  }, [auth])

  useEffect(() => {
    if (
      !config.okta.deactivate &&
      oktaAuth &&
      !authToken &&
      !pending &&
      !error
    ) {
      dispatch(fetchAuthToken('Okta'))
    }
  }, [oktaAuth, authToken, pending, error])

  return authToken
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getNow = () => Date.now()

export const getDelayBeforeExpiration = (token: string) => {
  const { exp } = parseJwt(token)
  return Math.trunc(exp * 1000 - getNow())
}
