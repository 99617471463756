import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLogItem } from '../../../store/log/actions'
import { cancelOrder, submitOrder } from '../../../store/order/actions'
import { getAggressErrors } from '../../../store/order/selectors'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import {
  getMaxTradeSize,
  getMaxTradeSizeIsHardLimit
} from '../../../store/userPreferences/selectors'
import { removeAggressorWindow } from '../../../store/windows/actions'
import { AggressorWindowInfo } from '../../../store/windows/types'
import AggressorModalFooter from '../AggressorModalFooter/AggressorModalFooter'
import styles from '../modal.module.scss'
import HeaderModal from '../ModalHeader/ModalHeader'
import ModalNotification from '../ModalNotification/ModalNotification'
import ModalSecurityInformationWrapper from '../ModalSecurityInformationWrapper'
import AggressorModalContent from './AggressorModalContent'
import {
  getAggressorOrderForAggressorWindow,
  useAggressorModalOrders,
  useSecurityModalInfo,
  useValueChangeAlert
} from './helpers'

export interface Props {
  windowInfo: AggressorWindowInfo
  index: number
  isActiveWindow: boolean
  handleMinimize: (index: number) => void
}

const AggressorModal: FC<Props> = ({
  windowInfo,
  isActiveWindow = true,
  handleMinimize,
  index
}) => {
  const { initialOrder } = useAggressorModalOrders(windowInfo)
  const errorMsg = useSelector(getAggressErrors)(windowInfo.transactionId)
  const maxTradeSize = useSelector(getMaxTradeSize)
  const maxTradeSizeIsHardLimit = useSelector(getMaxTradeSizeIsHardLimit)

  const aggressorOrder = getAggressorOrderForAggressorWindow(
    windowInfo.transactionId
  )
  const [size, setSize] = useState(0)
  const securityModalInfo = useSecurityModalInfo(
    windowInfo.initialOrderId,
    size
  )
  const [selectedAccount, setSelectedAccount] = useState(
    securityModalInfo?.accountIdToName[securityModalInfo.defaultAccountId]
  )
  const security = useSelector(getSecurityStaticDataById)(windowInfo.securityId)

  const dispatch = useDispatch()

  const [tradeAmt, setTradeAmt] = useState(
    !securityModalInfo || !securityModalInfo.maximumSize
      ? ''
      : String(securityModalInfo.maximumSize)
  )
  const isCancelAttempt = aggressorOrder?.status === 'cancelled'
  const [isAmountInputNOK, setCheckAmountInputNOK] = useState(false)
  const [initialPrice, setInitialPrice] = useState<number | undefined>()
  const [initialSize, setInitialSize] = useState<number | undefined>()
  const [initialAmount, setInitialAmount] = useState(0)
  const [buySellAmount, setBuySellAmount] = useState(0)
  const [spotCrossSelection, setSpotCrossSelection] = useState('')

  const minSizeForTsyHedge = securityModalInfo?.minSizeForTsyHedge
    ? securityModalInfo?.minSizeForTsyHedge
    : '500'

  useEffect(() => {
    if (securityModalInfo) {
      securityModalInfo.spotHedgePreference.startsWith('H') &&
      securityModalInfo.benchmarkPrice &&
      Number(tradeAmt) >= Number(minSizeForTsyHedge)
        ? setSpotCrossSelection('H')
        : setSpotCrossSelection('S')
    }
  }, [securityModalInfo?.spotHedgePreference, tradeAmt])

  useEffect(() => {
    const newSize = Number(tradeAmt)
    if (!Number.isNaN(newSize) && newSize !== size && !isAmountInputNOK) {
      setSize(newSize)
    }
  }, [tradeAmt])

  const alertMsgAmountLessQuantity = 'Amount is less than minimum quantity'
  const alertMsgAmountMoreQuantity = 'Amount is more than maximum quantity'
  const alertMsgAmountMustBeAnInteger = 'Amount must be an integer'
  const alertMsgPriceChange = 'Price has changed'
  const alertMsgSizeChange = 'Size has changed'
  const notificationModalType = 'alert'

  const shouldAlertPriceChange = useValueChangeAlert(
    aggressorOrder?.status,
    ['waitingForConfirmation', 'accepted'],
    securityModalInfo?.price,
    buySellAmount
  )

  const shouldAlertSizeChange = useValueChangeAlert(
    undefined,
    [],
    securityModalInfo?.maximumSize,
    buySellAmount
  )

  const type = useMemo(
    () => (initialOrder?.type === 'buy' ? 'sell' : 'buy'),
    []
  )

  const isOrderPending =
    aggressorOrder?.status === 'creationPending' ||
    aggressorOrder?.status === 'pending' ||
    aggressorOrder?.status === 'cancelled'

  const liquidityRemoved = !initialOrder && isOrderPending

  useEffect(() => {
    if (aggressorOrder?.status === 'waitingForConfirmation') {
      setCheckAmountInputNOK(false)
    } else if (aggressorOrder?.status === 'cancelled' && securityModalInfo) {
      const amount = Number(tradeAmt)
      setCheckAmountInputNOK(
        initialOrder === undefined ||
          isNaN(amount) ||
          !Number.isInteger(amount) ||
          amount > securityModalInfo.maximumSize ||
          amount < securityModalInfo.minimumSize
      )
    }
  }, [aggressorOrder?.status])

  useEffect(() => {
    if (initialOrder && securityModalInfo) {
      setCheckAmountInputNOK(
        isNaN(initialOrder.size) ||
          !Number.isInteger(initialOrder.size) ||
          initialOrder.size > securityModalInfo.maximumSize ||
          initialOrder.size < securityModalInfo.minimumSize ||
          Number(tradeAmt) > initialOrder.size
      )
    }
  }, [initialOrder?.size])

  useEffect(() => {
    if (securityModalInfo && initialOrder) {
      if (initialPrice === undefined) {
        setInitialSize(initialOrder.size)
      }
      if (initialSize === undefined) {
        setInitialPrice(initialOrder.price)
      }
    }
  }, [securityModalInfo, initialOrder])

  const [sizeInitialized, setSizeInitialized] = useState(false)

  useEffect(() => {
    if (
      securityModalInfo &&
      securityModalInfo.maximumSize &&
      !sizeInitialized &&
      (!tradeAmt || securityModalInfo.maximumSize <= Number(tradeAmt))
    ) {
      setSizeInitialized(true)
      setTradeAmt(String(securityModalInfo.maximumSize))
      setSelectedAccount(
        securityModalInfo?.accountIdToName[securityModalInfo.defaultAccountId]
      )
    }
  }, [securityModalInfo])

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCheckAmountInputNOK(false)

    setTradeAmt('')
    dispatch(removeAggressorWindow(windowInfo))
  }

  const handleCancelAttempt = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (aggressorOrder) {
      dispatch(cancelOrder(aggressorOrder.id))
      dispatch(
        addLogItem(
          'handleCancelAttempt for order ' +
            aggressorOrder.id +
            (security
              ? ' on security ' + security.id + ' / ' + security.cusip
              : '')
        )
      )
    } else {
      console.error('orderId not found')
    }
  }

  const onChangeTradeAmt = (newValue: string) => {
    const amount = Number(newValue)
    setCheckAmountInputNOK(
      isNaN(amount) ||
        !Number.isInteger(amount) ||
        amount > securityModalInfo!.maximumSize ||
        amount < securityModalInfo!.minimumSize
    )
    setTradeAmt(newValue)
  }

  const showMakerAlerts = () => {
    if (shouldAlertPriceChange || shouldAlertSizeChange) {
      return (
        <>
          {shouldAlertPriceChange && (
            <ModalNotification
              colorStyle={notificationModalType}
              messageNotification={alertMsgPriceChange}
            />
          )}
          {shouldAlertSizeChange && (
            <ModalNotification
              colorStyle={notificationModalType}
              messageNotification={alertMsgSizeChange}
            />
          )}
        </>
      )
    }
  }

  const showTakerAlerts = (
    amountQty: number,
    qtyMax: number,
    qtyMin: number
  ) => {
    let notif = ''
    if (!Number.isInteger(Number(amountQty))) {
      notif = alertMsgAmountMustBeAnInteger
    } else if (amountQty > qtyMax) {
      notif = alertMsgAmountMoreQuantity
    } else if (amountQty < qtyMin) {
      notif = alertMsgAmountLessQuantity
    }
    return notif ? (
      <ModalNotification
        colorStyle={notificationModalType}
        messageNotification={notif}
      />
    ) : null
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleSellOrBuySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setCheckAmountInputNOK(true)
    if (initialOrder?.id) {
      let custId = 0
      if (securityModalInfo) {
        const strArr = Object.keys(securityModalInfo.accountIdToName)
        const numArr = strArr.map((n) => Number(n))
        custId =
          numArr.find(
            (key) => securityModalInfo.accountIdToName[key] === selectedAccount
          ) ?? 0
      }
      dispatch(
        submitOrder(
          initialOrder,
          initialOrder.id,
          Number(tradeAmt),
          windowInfo.transactionId,
          custId,
          spotCrossSelection,
          windowInfo.listId
        )
      )
      dispatch(
        addLogItem(
          'handleSellOrBuySubmit (aggress) aggressing ' +
            initialOrder.id +
            ', securityId: ' +
            initialOrder.securityId +
            ', price: ' +
            initialOrder.price +
            ', size: ' +
            tradeAmt +
            ', side: ' +
            (initialOrder.type === 'buy' ? 'sell' : 'buy') +
            windowInfo.listId
            ? `listId: ${windowInfo.listId}`
            : ''
        )
      )
      setBuySellAmount(Number(tradeAmt))
      setInitialAmount(initialOrder.size)
    }
  }

  useEffect(() => {
    // allows aggressor values to keep updating if there's an error
    errorMsg && setBuySellAmount(0)
  }, [errorMsg])

  if (!securityModalInfo || !security) {
    return null
  }

  const fontClassName = type === 'buy' ? styles.buyColor : styles.sellColor

  const expandedOrCollapsed = isActiveWindow
    ? styles.expandableModal
    : styles.collapsedModal

  const initialAmt = initialAmount > 0 ? initialAmount : initialOrder?.size

  const completedTradeAmt = buySellAmount > 0 ? buySellAmount : tradeAmt

  return (
    <div className={styles.modalWrapper}>
      <form onSubmit={handleSellOrBuySubmit} onKeyDown={handleEnter}>
        <section className={styles.modalMain}>
          <HeaderModal
            type={type}
            security={security}
            securityModalInfo={securityModalInfo}
            styles={styles}
            isLastLook={false}
            isActiveWindow={isActiveWindow}
            index={index}
            handleMinimize={handleMinimize}
            orderStatusComp={aggressorOrder?.status}
          />
          {(aggressorOrder?.status === 'creationPending' ||
            aggressorOrder?.status === 'cancelled') && (
            <>
              {showTakerAlerts(
                Number(tradeAmt),
                securityModalInfo.maximumSize,
                securityModalInfo.minimumSize
              )}
              {showMakerAlerts()}
            </>
          )}
          <div className={`${styles.contentExpandable} ${expandedOrCollapsed}`}>
            <AggressorModalContent
              order={aggressorOrder!}
              securityModalInfo={securityModalInfo}
              onChangeTradeAmt={onChangeTradeAmt}
              spotCrossSelection={spotCrossSelection}
              setSpotCrossSelection={setSpotCrossSelection}
              styles={styles}
              tradeAmt={tradeAmt}
              type={type}
              orderStatusComp={aggressorOrder?.status}
              shouldAlertPriceChange={shouldAlertPriceChange}
              shouldAlertSizeChange={shouldAlertSizeChange}
              selectedAccount={selectedAccount ?? ''}
              setSelectedAccount={setSelectedAccount}
              currency={security.currency}
              buySellAmount={buySellAmount}
              isTreasury={security.product === 'PrinUSGovtOutright'}
            />
            <ModalSecurityInformationWrapper
              securityModalInfo={securityModalInfo}
              security={security}
            />

            {aggressorOrder && (
              <AggressorModalFooter
                order={aggressorOrder}
                fontClassName={fontClassName}
                orderStatusComp={aggressorOrder?.status}
                size={initialAmt}
                currentSize={size}
                type={type}
                tradeAmt={Number(completedTradeAmt)}
                handleCancelAttempt={handleCancelAttempt}
                isCancelAttempt={isCancelAttempt}
                actionCloseModal={handleCloseModal}
                isAmountInputNOK={isAmountInputNOK}
                liquidityRemoved={liquidityRemoved}
                error={errorMsg}
                maxTradeSize={maxTradeSize}
                maxTradeSizeIsHardLimit={maxTradeSizeIsHardLimit}
                benchmarkName={security.benchmarkName}
                spotCrossSelection={spotCrossSelection}
              />
            )}
          </div>
        </section>
      </form>
    </div>
  )
}

export default AggressorModal
