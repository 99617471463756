import cx from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getTotalSecondsBeforeExpiration } from '../../../helpers/orders'
import { Styles } from '../../../helpers/types'
import { SpotCrossSelection } from '../../../store/lastLook/types'
import { Order, OrderStatus, OrderType } from '../../../store/order/types'
import { showTsySetting } from '../../../store/webSettings/selectors'
import { SecurityModalInfo } from '../../../store/windows/types'
import { getServerTimeDelayMs } from '../../../store/ws/selectors'
import LeftInfoPanel from '../../AggressorAndLastLook/LeftInfoPanel'
import PriceHedge from '../../AggressorAndLastLook/PriceHedge'
import TimerLeftComponent from '../../Timer/Timer'

interface Props {
  order: Order
  type: OrderType
  securityModalInfo: SecurityModalInfo
  tradeAmt: string | undefined
  // FIXME: define style classname types
  styles: Styles
  price: number
  orderStatusComp: OrderStatus | undefined
  shouldAlertPriceChange?: boolean
  spotCrossSelection: string
  setSpotCrossSelection: (st: SpotCrossSelection) => void
  selectedAccount?: string
  setSelectedAccount?: (st: string) => void
  currency: string
  isTreasury: boolean
}

const LastLookModalContent: FC<Props> = (props) => {
  const {
    order,
    securityModalInfo,
    tradeAmt,
    styles,
    orderStatusComp,
    shouldAlertPriceChange,
    spotCrossSelection,
    setSpotCrossSelection,
    selectedAccount,
    setSelectedAccount,
    currency,
    isTreasury
  } = props

  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)
  const showTsy = useSelector(showTsySetting)

  const isActive = orderStatusComp === 'waitingForConfirmation'

  const rightSideInfoClasses = cx(
    styles.rightInfos,
    orderStatusComp !== 'accepted' && !isActive && styles.timedOut
  )

  const minSizeForTsyHedge = securityModalInfo.minSizeForTsyHedge || '500'

  const disableButton =
    !securityModalInfo?.benchmarkPrice ||
    ['accepted', 'rejected', 'cancelled'].includes(orderStatusComp ?? '')

  const disableCrossButton =
    disableButton || Number(tradeAmt) < Number(minSizeForTsyHedge)

  const timerColors =
    isActive || orderStatusComp === 'accepted'
      ? 'var(--activeBackgroundColor)'
      : 'var(--primaryTextColor)'

  const { totalSeconds } = getTotalSecondsBeforeExpiration(
    order.aggressorOrder?.expiration,
    order.aggressorOrder?.submitTime,
    serverTimeDelayMs
  )
  return (
    <div
      className={cx(
        styles.content,
        setSelectedAccount ? styles.aggressorContent : styles.lastLookContent
      )}
    >
      <LeftInfoPanel
        currency={currency}
        isTreasury={isTreasury}
        priceValueStyles={shouldAlertPriceChange ? styles.warning : ''}
        securityModalInfo={securityModalInfo}
        selectedAccount={selectedAccount}
        /* setSelectedAccount={setSelectedAccount}*/
        tradeAmt={
          <p data-testid="lastlook-amt" className={styles.value}>
            {tradeAmt}
          </p>
        }
        updateOnChanges={[securityModalInfo.price]}
      />
      <div className={rightSideInfoClasses}>
        <div className={styles.timerWrapper}>
          <TimerLeftComponent
            valueColor={timerColors}
            timerInit={totalSeconds}
            isPlaying={isActive}
            valueSize="big"
            totalSecondsBeforeExpiration={{
              expiration: order.aggressorOrder?.expiration,
              submitTime: order.aggressorOrder?.submitTime,
              serverTimeDelayMs
            }}
          />
        </div>
        {showTsy && (
          <PriceHedge
            disableBtns={disableButton}
            disableCrossBtn={disableCrossButton}
            setSpotCrossSelection={setSpotCrossSelection}
            spotCrossSelection={spotCrossSelection}
          />
        )}
      </div>
    </div>
  )
}

export default LastLookModalContent
