import { faBan } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import {
  faCheck,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import {
  forceCancel,
  markAsRestricted,
  markAsUnrestricted
} from '../../../store/depthOfMarket/actions'
import { Order } from '../../../store/order/types'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import {
  getCanForceCancel,
  getCanMarkRestricted
} from '../../../store/webSettings/selectors'
import styles from './Admintools.module.scss'

interface Props {
  order: Order
}

const AdminTools = ({ order }: Props) => {
  const dispatch = useAppDispatch()
  const canMarkRestricted = useAppSelector(getCanMarkRestricted)
  const canForceCancel = useAppSelector(getCanForceCancel)
  const isExternal = order?.id.startsWith('E')
  const isPending = order?.status === 'pending'
  const notAvailable = order?.status === 'notAvailable'

  const showRestrict = canMarkRestricted && isExternal && isPending
  const showUnrestrict = canMarkRestricted && isExternal && notAvailable
  const showForceCancel = canForceCancel && isExternal

  const rootId = `${order.securityId}-${order.type}-`
  const cancelId = rootId + 'cancel'
  const restrictId = rootId + 'restrict'

  const restrictOrder = useCallback(() => {
    dispatch(markAsRestricted(order.id))
  }, [order.id])
  const unrestrictOrder = useCallback(() => {
    dispatch(markAsUnrestricted(order.id))
  }, [order.id])
  const cancelOrder = useCallback(() => {
    dispatch(forceCancel(order.id))
  }, [order.id])

  return (
    <td>
      <div className={styles.adminCell}>
        <span className={styles.displayName} title={order.displayName ?? ''}>
          {order.displayName}
        </span>
        {showForceCancel ? (
          <span
            data-testid={cancelId}
            onClick={cancelOrder}
            className="pointer"
            title="Force Cancel"
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        ) : (
          <></>
        )}
        {showRestrict ? (
          <span
            data-testid={restrictId}
            onClick={restrictOrder}
            title="Mark Restricted"
            className="pointer"
          >
            <FontAwesomeIcon icon={faBan} />
          </span>
        ) : (
          <></>
        )}
        {showUnrestrict ? (
          <span
            data-testid={restrictId}
            onClick={unrestrictOrder}
            title="Mark Unrestricted"
            className="pointer"
          >
            <FontAwesomeIcon icon={faCheck} />
          </span>
        ) : (
          <></>
        )}
      </div>
    </td>
  )
}

export default AdminTools
