import { CustomCellEditorProps } from '@ag-grid-community/react'
import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import styles from '../Upload/EnhancedUpload/styles.module.css'
import { SymbolEditorParams } from '../Upload/EnhancedUpload/types'

type Props<GridDataType> = CustomCellEditorProps<GridDataType, string> &
  SymbolEditorParams

const pastedTxt = /\W/g

const SearchingSymbolEditor = <T,>({
  eventKey,
  onValueChange,
  rowIndex,
  selector,
  setSearchString,
  stopEditing,
  value = '',
  valueListId
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const updateValue = useCallback(
    (newValue: string) => {
      if (!pastedTxt.test(newValue)) {
        setSearchString(newValue)
        onValueChange(newValue)
      } else {
        stopEditing()
      }
    },
    [setSearchString, onValueChange]
  )
  const onInputChange = useCallback(
    ({ currentTarget: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
      updateValue(newValue)
    },
    [updateValue]
  )
  useEffect(() => {
    switch (eventKey) {
      case 'Backspace': {
        updateValue('')
        break
      }
      default: {
        updateValue(eventKey || '')
        break
      }
    }
    inputRef.current?.focus()
  }, [])
  return (
    <input
      value={value || ''}
      onChange={onInputChange}
      list={valueListId}
      data-testid={`${selector}-admin-symbol-${rowIndex}`}
      className={styles.symbolEditorInput}
      ref={inputRef}
    />
  )
}

export default SearchingSymbolEditor
