import { setUser } from '@sentry/react'
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable'
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { fetchAuthTokenFailure } from '../auth/actions'
import { getAuthType } from '../auth/selectors'
import { marketClosed, marketCloseTimerTick } from './actions'
import { EMPTY, interval, of } from 'rxjs'
import { closeTimerCount } from './reducer'
import { getMarketClosed, getMarketClosedTimer } from './selectors'
import { getIsAdmin } from '../webSettings/selectors'

const marketClosedEpic: Epic = (action$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    switchMap(() => {
      return getHub().stream<boolean>('MarketClosed').pipe(map(marketClosed))
    })
  )

const signOut = (state$: StateObservable<any>) => {
  const marketIsClosed = getMarketClosed(state$.value)
  if (marketIsClosed) {
    setUser(null)
    const authType = getAuthType(state$.value)
    if (authType === 'Keycloak') {
      return of(fetchAuthTokenFailure('Markets closed'))
    }
    if (state$.value.auth?.oktaAuth) {
      state$.value.auth.oktaAuth.signOut({
        postLogoutRedirectUri: null
      })
      return EMPTY
    }
  }
  return EMPTY
}

const marketHasClosedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('market.marketClosed'),
    distinctUntilChanged(),
    switchMap((action) => {
      if (!getIsAdmin(state$.value)) {
        if (document.visibilityState === 'hidden') {
          return signOut(state$)
        }
        if (action.payload.closed) {
          return interval(1000).pipe(
            map(marketCloseTimerTick),
            take(closeTimerCount)
          )
        }
      }
      return EMPTY
    })
  )

const signoutAfterMarketClosedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('market.closeTimerTick'),
    switchMap(() => {
      const timeRemaining = getMarketClosedTimer(state$.value)
      if (timeRemaining) return EMPTY
      return signOut(state$)
    })
  )

export default combineEpics(
  marketClosedEpic,
  marketHasClosedEpic,
  signoutAfterMarketClosedEpic
)
