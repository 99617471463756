import { OktaAuthOAuthInterface } from '@okta/okta-auth-js'
import { Action } from 'redux'
import { AuthType } from './reducer'

export interface FetchAuthTokenFailureAction extends Action {
  type: 'auth.fetch-auth-token-failure'
  error: true
  payload: any
}

export interface RedirectToLoginAction extends Action {
  type: 'auth.redirect-to-login'
}

export const fetchAuthToken = (type: AuthType, isRefresh = false) =>
  ({
    type: 'auth.fetch-auth-token',
    payload: { type, isRefresh }
  } as const)

export type FetchAuthTokenAction = ReturnType<typeof fetchAuthToken>

export const fetchAuthTokenSuccess = (
  authToken: string,
  userName: string,
  userId?: string
) =>
  ({
    type: 'auth.fetch-auth-token-success',
    payload: { authToken, userName, userId }
  } as const)

export type FetchAuthTokenSuccessAction = ReturnType<
  typeof fetchAuthTokenSuccess
>

export const fetchAuthTokenFailure = (
  error: any
): FetchAuthTokenFailureAction => ({
  type: 'auth.fetch-auth-token-failure',
  error: true,
  payload: error
})

export const redirectToLogin = (): RedirectToLoginAction => ({
  type: 'auth.redirect-to-login'
})

export const setOktaAuth = (auth: OktaAuthOAuthInterface) =>
  ({
    type: 'auth.set-okta-auth',
    payload: auth
  } as const)

type SetOktaAuthAction = ReturnType<typeof setOktaAuth>

export const setAuthInitialized = () =>
  ({
    type: `auth.setAuthInitialized`
  } as const)
export type SetAuthInitializedAction = ReturnType<typeof setAuthInitialized>

export type AuthAction =
  | FetchAuthTokenAction
  | FetchAuthTokenSuccessAction
  | FetchAuthTokenFailureAction
  | RedirectToLoginAction
  | SetOktaAuthAction
  | SetAuthInitializedAction
