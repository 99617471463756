import { setUser } from '@sentry/react'
import React, { useCallback, useContext } from 'react'
import { noop } from 'rxjs'
import { useOpenFin } from '../app/openFinContext'

/*
  While we could pass the signout function into App and then prop dril that into the menu and
  All Markets closed, I wasn't sure that would work well for Openfin, so I'm doing it like this
 */
type SessionContextType = {
  signout: () => void
  editProfile?: () => void
}

export const SessionContext = React.createContext<SessionContextType>({
  signout: noop,
  editProfile: noop
})

export const useSessionSignout = () => {
  const { signout } = useContext(SessionContext)
  return signout
}

export const useUserSession = () => {
  const { signout, editProfile } = useContext(SessionContext)
  const { fin, toggleFrame, manageWindows } = useOpenFin()

  const handleSignout = useCallback(() => {
    if (fin) {
      toggleFrame(true)
      manageWindows(null, 'hide')
    }
    signout()
    setUser(null)
  }, [signout, toggleFrame, manageWindows])

  return {
    handleSignout,
    editProfile
  }
}
