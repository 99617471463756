import {
  BBMISIN,
  BBMISIN_CLEAR_ERROR,
  BBMISIN_SAVE,
  BBMISIN_SAVE_FAILURE,
  BBMISIN_SAVE_SUCCESS,
  BBMISINAction,
  BBMISINClearAction,
  BBMISINSaveFailureAction,
  BBMISINSaveSuccessAction
} from './types'

export const saveBBMISINs = (bbmIsins: BBMISIN[]): BBMISINAction => ({
  type: BBMISIN_SAVE,
  payload: bbmIsins
})

export const saveBBMISINsSuccess = (errors: any): BBMISINSaveSuccessAction => ({
  type: BBMISIN_SAVE_SUCCESS,
  payload: errors
})

export const saveBBMISINsFailure = (error: any): BBMISINSaveFailureAction => ({
  type: BBMISIN_SAVE_FAILURE,
  payload: error,
  error: true
})

export const clearError = (): BBMISINClearAction => ({
  type: BBMISIN_CLEAR_ERROR
})
