import { Action } from 'redux'
import { SecuritySearchResult } from './types'

export interface SearchSecuritiesAction extends Action {
  type: 'searchSecurities.searchSecurities'
  payload: {
    gridIndex: number
    query: string
    ignoreWatchlist: boolean
    useGridFilters: boolean
  }
}

export const searchSecurities = (
  gridIndex: number,
  query: string,
  ignoreWatchlist: boolean,
  useGridFilters: boolean = false
): SearchSecuritiesAction => ({
  type: 'searchSecurities.searchSecurities',
  payload: { gridIndex, query, ignoreWatchlist, useGridFilters }
})

export interface SearchSecuritiesSuccessAction extends Action {
  type: 'searchSecurities.searchSecuritiesSuccess'
  payload: {
    gridIndex: number
    securities: SecuritySearchResult[]
    ignoreWatchlist: boolean
  }
}

export const searchSecuritiesSuccess = (
  gridIndex: number,
  securities: SecuritySearchResult[],
  ignoreWatchlist: boolean
): SearchSecuritiesSuccessAction => ({
  type: 'searchSecurities.searchSecuritiesSuccess',
  payload: { gridIndex, securities, ignoreWatchlist }
})

export interface SearchSecuritiesFailureAction extends Action {
  type: 'searchSecurities.searchSecuritiesFailure'
  error: true
  payload: { gridIndex: number; error: any }
}

export const searchSecuritiesFailure = (
  gridIndex: number,
  error: any
): SearchSecuritiesFailureAction => ({
  type: 'searchSecurities.searchSecuritiesFailure',
  error: true,
  payload: { gridIndex, error }
})

export interface SelectFirstIssuerResultAction extends Action {
  type: 'searchSecurities.selectFirstIssuerResult'
  payload: { gridIndex: number }
}

export const selectFirstIssuerResult = (
  gridIndex: number
): SelectFirstIssuerResultAction => ({
  type: 'searchSecurities.selectFirstIssuerResult',
  payload: { gridIndex }
})

export const globalSearch = (query: string) =>
  ({
    type: `searchSecurities.globalSearch`,
    payload: query
  } as const)
export type GlobalSearchAction = ReturnType<typeof globalSearch>

export const globalSearchSuccess = (searchResults: SecuritySearchResult[]) =>
  ({
    type: `searchSecurities.globalSearchSuccess`,
    payload: { searchResults }
  } as const)
export type GlobalSearchSuccessAction = ReturnType<typeof globalSearchSuccess>

export const clearGlobalSearch = () =>
  ({
    type: `searchSecurities.clearGlobalSearch`
  } as const)
export type ClearGlobalSearchAction = ReturnType<typeof clearGlobalSearch>

export type SearchSecuritiesActions =
  | SearchSecuritiesAction
  | SearchSecuritiesSuccessAction
  | SearchSecuritiesFailureAction
  | SelectFirstIssuerResultAction
  | GlobalSearchAction
  | GlobalSearchSuccessAction
  | ClearGlobalSearchAction
