import dayjs from 'dayjs'
import React, { FC, useEffect, useState } from 'react'
import { clearError, saveBBMISINs } from '../../../store/admin/bbmisin/actions'
import { StringToBBMISINList } from '../../../store/admin/bbmisin/helpers'
import { getBBMISINErrors } from '../../../store/admin/bbmisin/selectors'
import { addToast } from '../../../store/toasts/actions'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import { finHeaderButtons } from '../../ComponentHeader/helpers'
import gridStyles from '../../Grid/grid.module.scss'

const BBMISIN: FC = () => {
  const dispatch = useAppDispatch()
  const serverErrors = useAppSelector(getBBMISINErrors)
  const [bbmIsinData, setBbmIsinData] = useState('')
  const [frontEndError, setFrontEndError] = useState('')
  const [backEndError, setBackEndError] = useState('')

  useEffect(() => {
    setBackEndError(serverErrors)
  }, [serverErrors])

  const uploadData = () => {
    const { bbmIsinList, errors } = StringToBBMISINList(bbmIsinData)
    if (bbmIsinList.length > 0) {
      dispatch(saveBBMISINs(bbmIsinList))
    }
    setFrontEndError(errors.join('\n'))
  }

  useEffect(() => {
    if (frontEndError || backEndError) {
      dispatch(
        addToast({
          message: frontEndError || backEndError,
          options: {
            toastId: `bbmisin-${dayjs().toString()}`,
            type: 'error',
            onClose: () => {
              setFrontEndError('')
              setBackEndError('')
              dispatch(clearError())
            }
          }
        })
      )
    }
  }, [frontEndError, backEndError])

  return (
    <div>
      <ComponentHeader
        title="BBMISIN"
        headerButtons={finHeaderButtons('BBMISIN')}
      />
      <div className={gridStyles.optionsContainer}>
        <button onClick={uploadData} disabled={!bbmIsinData}>
          Upload
        </button>
      </div>
      <textarea
        rows={20}
        placeholder="Cusip/ISIN BBMISIN"
        value={bbmIsinData}
        onChange={(e) => setBbmIsinData(e.target.value)}
      />
    </div>
  )
}

export default BBMISIN
