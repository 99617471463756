import { CustomCellEditorProps } from '@ag-grid-community/react'
import React, { ChangeEvent, useCallback } from 'react'
import { getBooks } from '../../store/books/selectors'
import { useAppSelector } from '../../store/types'

type Props<GridDataType> = CustomCellEditorProps<GridDataType, number>

const BookSelectEditor = <T,>({
  onValueChange,
  rowIndex,
  value = -1
}: Props<T>) => {
  const books = useAppSelector(getBooks)

  const onValueSelected = useCallback(
    ({
      currentTarget: { value: newValue }
    }: ChangeEvent<HTMLSelectElement>) => {
      onValueChange(Number(newValue))
    },
    []
  )

  return (
    <select
      onChange={onValueSelected}
      data-testid={`select-book-row-${rowIndex}`}
      defaultValue={value || -1}
    >
      {books?.map((book, i) => (
        <option key={i} value={book.id}>
          {book.name}
        </option>
      ))}
    </select>
  )
}

export default BookSelectEditor
