import React, { ChangeEvent, useCallback } from 'react'
import styles from '../Modal/modal.module.scss'

interface Props {
  inputDisabled: boolean
  inputClass?: string
  minimumSize: number
  onChange?: (newVal: string) => void
  value: string | undefined
}

const TradeAmountInput = ({
  inputDisabled,
  inputClass,
  minimumSize,
  onChange,
  value
}: Props) => {
  const handleChange = useCallback(
    ({
      currentTarget: { value: inputValue }
    }: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(inputValue)
      }
    },
    []
  )
  return (
    <>
      <input
        autoFocus
        value={value}
        data-testid="aggressor-trade-amt"
        onChange={handleChange}
        className={inputClass}
        disabled={inputDisabled}
      />
      <span className={styles.minQty}>
        Minimum Qty:{' '}
        <span data-testid="aggressor-minimumquantity">{minimumSize} </span>
      </span>
    </>
  )
}

export default TradeAmountInput
