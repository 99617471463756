import {
  faAnglesDown,
  faAnglesRight,
  faArrowUp,
  faBell,
  faBellPlus,
  faBellSlash,
  faChalkboardUser,
  faChartSimpleHorizontal,
  faCircleInfo,
  faClipboardList,
  faCookieBite,
  faDesktop,
  faEnvelope,
  faExclamationCircle,
  faGear,
  faList,
  faMemoCircleInfo,
  faMoon,
  faPhone,
  faShieldCheck,
  faSunBright,
  faTimes,
  faWavePulse
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toggleNotificationCenter } from 'openfin-notifications'
import React, { FC, useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import SSIPDF from '../../../assets/pdfs/SSI.pdf'
import UserPDF from '../../../assets/pdfs/UserGuide.pdf'
import { toggleActivityPanel } from '../../../store/activity/actions'
import { getActivityPanelVisible } from '../../../store/activity/selectors'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { setUserPreferences } from '../../../store/userPreferences/actions'
import {
  getUseLightTheme,
  getUserPreferences
} from '../../../store/userPreferences/selectors'
import {
  getAllowOrderEntry,
  getHasTradingRights,
  showLTSetting
} from '../../../store/webSettings/selectors'
import AlertFilterEditor from '../../AlertFilter/AlertFilterEditor'
import SettingsModal from '../../SettingsModal/SettingsModal'
import styles from './menus.module.scss'

export interface Props {
  fin: boolean
  closeMenu: () => void
  handleNavigation: (item: string) => void
}

const ClientMenu: FC<Props> = ({ fin, closeMenu, handleNavigation }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const activityPanelVisible = useAppSelector(getActivityPanelVisible)
  const [traderPrefs, setShowTraderPrefs] = useState(false)
  const [alertFilter, setShowAlertFilter] = useState(false)
  const [helpMenuOpen, setHelpMenuOpen] = useState(false)
  const allowOrderEntry = useAppSelector(getAllowOrderEntry)
  const userPreferences = useAppSelector(getUserPreferences)
  const useLightTheme = useAppSelector(getUseLightTheme)
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const showLT = useAppSelector(showLTSetting)

  // This is temporary, see todo below
  const handleTraderPrefs = useCallback(() => {
    setShowTraderPrefs(!traderPrefs)
  }, [traderPrefs])

  const handleAlertFilter = useCallback(() => {
    fin ? handleNavigation('AlertManager') : setShowAlertFilter(!alertFilter)
  }, [alertFilter])

  const toggleActivity = useCallback(() => {
    dispatch(toggleActivityPanel())
  }, [])

  const toggleTheme = () => {
    const newPreferences = {
      ...userPreferences,
      useLightTheme: !userPreferences.useLightTheme
    }
    dispatch(setUserPreferences(newPreferences))
  }

  return (
    <div className={fin ? styles.menuContainer : ''}>
      {traderPrefs && <SettingsModal closeFunc={handleTraderPrefs} />}
      {alertFilter && <AlertFilterEditor closeFunc={handleAlertFilter} />}

      {(fin || (!fin && showLT)) && (
        <>
          <div className={styles.menuSection}>
            Menu
            {fin && (
              <span onClick={closeMenu}>
                <FontAwesomeIcon icon={faTimes} title="Close Menu" />
              </span>
            )}
          </div>
          <div className={styles.optionsContainer}>
            {fin && (
              <div
                className={styles.dropdownButton}
                onClick={() => handleNavigation('Benchmarks')}
              >
                <FontAwesomeIcon icon={faArrowUp} />
                <span>Show Benchmarks</span>
              </div>
            )}
            {hasTradingRights && showLT && (
              <div
                className={styles.dropdownButton}
                onClick={() => handleNavigation('ListTrading')}
              >
                <FontAwesomeIcon icon={faClipboardList} />
                <span>List Trading</span>
              </div>
            )}
          </div>
        </>
      )}

      {/* TODO: data isnt coming back in openfin window */}
      <div className={styles.menuSection}>Settings</div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.dropdownButton}
          onClick={handleTraderPrefs}
          // onClick={() => handleNavigation('Settings')}
        >
          <FontAwesomeIcon icon={faGear} />
          <span>Trader Preferences</span>
        </div>

        <div className={styles.dropdownButton} onClick={handleAlertFilter}>
          <FontAwesomeIcon icon={faBellPlus} />
          <span>Alert Manager</span>
        </div>

        <div className={styles.dropdownButton} onClick={toggleTheme}>
          {useLightTheme ? (
            <FontAwesomeIcon icon={faMoon} />
          ) : (
            <FontAwesomeIcon icon={faSunBright} />
          )}
          <span>{`Switch to ${useLightTheme ? 'Dark' : 'Light'} Theme`}</span>
        </div>
      </div>

      <div className={styles.menuSection}>Activity</div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('ActivityBlotter')}
        >
          <FontAwesomeIcon icon={faChartSimpleHorizontal} />
          <span>My Trade Blotter</span>
        </div>
        {allowOrderEntry && (
          <div
            className={styles.dropdownButton}
            onClick={() => handleNavigation('PassiveOrders')}
          >
            <FontAwesomeIcon icon={faList} />
            <span>My Orders</span>
          </div>
        )}
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('ActivityLog')}
        >
          <FontAwesomeIcon icon={faWavePulse} />
          <span>My Activity Log</span>
        </div>
        {!fin && location.pathname === '/' && (
          <div className={styles.dropdownButton} onClick={toggleActivity}>
            {activityPanelVisible ? (
              <FontAwesomeIcon icon={faBellSlash} />
            ) : (
              <FontAwesomeIcon icon={faBell} />
            )}
            <span>{`${
              activityPanelVisible ? 'Hide' : 'Show'
            } Activity Panel`}</span>
          </div>
        )}
        {fin && (
          <div
            className={styles.dropdownButton}
            /* eslint-disable*/
            onClick={() => toggleNotificationCenter()}
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span>Notification Center</span>
          </div>
        )}
      </div>
      <div
        className={styles.menuSection}
        onClick={() => setHelpMenuOpen(!helpMenuOpen)}
      >
        Help
        <FontAwesomeIcon icon={helpMenuOpen ? faAnglesDown : faAnglesRight} />
      </div>
      {helpMenuOpen && (
        <div className={styles.optionsContainer}>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faPhone} />
            <a href="tel:2122574062">(212) 257-4062</a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:help@bonds.com">help@bonds.com</a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faChalkboardUser} />
            <a href={UserPDF} download="bonds.com_userGuide.pdf">
              Self Guided Tour PDF
            </a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faMemoCircleInfo} />
            <a href={SSIPDF} download="bonds.com_ssi.pdf">
              Settlement Instructions (SSI)
            </a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faDesktop} />
            <a href="https://www.bonds.com/" target="_blank" rel="noreferrer">
              Corporate website
            </a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faCookieBite} />
            <a
              href="https://bonds.com/cookie-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faShieldCheck} />
            <a
              href="https://bonds.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:hello@bonds.com">Request Trading Rules</a>
          </div>
          <div className={styles.dropdownButton}>
            <FontAwesomeIcon icon={faCircleInfo} />
            <a
              href="https://bonds.com/regulatory-information/"
              target="_blank"
              rel="noreferrer"
            >
              More regulatory info
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientMenu
