import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatAggressorLLPrice } from '../../../helpers/formatting'
import { Styles } from '../../../helpers/types'
import { Order, OrderStatus, OrderType } from '../../../store/order/types'
import { showTsySetting } from '../../../store/webSettings/selectors'
import { SecurityModalInfo } from '../../../store/windows/types'
import LeftInfoPanel from '../../AggressorAndLastLook/LeftInfoPanel'
import PriceHedge from '../../AggressorAndLastLook/PriceHedge'
import TradeAmountInput from '../../AggressorAndLastLook/TradeAmountInput'

interface Props {
  order: Order
  type: OrderType
  securityModalInfo: SecurityModalInfo
  tradeAmt: string | undefined
  // FIXME: define style classname types
  styles: Styles
  orderStatusComp: OrderStatus | undefined
  shouldAlertPriceChange?: boolean
  shouldAlertSizeChange?: boolean
  onChangeTradeAmt?: (newValue: string) => void
  spotCrossSelection: string
  setSpotCrossSelection: (st: string) => void
  selectedAccount?: string
  setSelectedAccount?: (st: string) => void
  currency: string
  buySellAmount: number
  isTreasury: boolean
}

const AggressorModalContent: FC<Props> = (props) => {
  const {
    type,
    securityModalInfo,
    tradeAmt,
    styles,
    onChangeTradeAmt,
    spotCrossSelection,
    setSpotCrossSelection,
    orderStatusComp,
    shouldAlertPriceChange,
    shouldAlertSizeChange,
    selectedAccount,
    setSelectedAccount,
    currency,
    buySellAmount,
    isTreasury
  } = props

  const showTsy = useSelector(showTsySetting)

  const [allInPrice, setAllInPrice] = useState(0)
  const [bmPrice, setBmPrice] = useState<number | undefined>(0)

  useEffect(() => {
    if (
      (orderStatusComp === 'creationPending' && !buySellAmount) ||
      orderStatusComp === 'cancelled'
    ) {
      setAllInPrice(securityModalInfo.allInPrice)
      setBmPrice(securityModalInfo.benchmarkPrice)
    }
  }, [
    orderStatusComp,
    securityModalInfo.allInPrice,
    securityModalInfo.benchmarkPrice
  ])

  const rightSideInfoClasses = cx(styles.rightInfos)

  const minSizeForTsyHedge = securityModalInfo.minSizeForTsyHedge
    ? securityModalInfo.minSizeForTsyHedge
    : '500'

  const disableButton =
    orderStatusComp === 'accepted' ||
    orderStatusComp === 'rejected' ||
    orderStatusComp === 'waitingForConfirmation'

  const disableCrossButton =
    disableButton || !bmPrice || Number(tradeAmt) < Number(minSizeForTsyHedge)

  return (
    <div
      className={cx(
        styles.content,
        setSelectedAccount ? styles.aggressorContent : styles.lastLookContent
      )}
    >
      <LeftInfoPanel
        currency={currency}
        isTreasury={isTreasury}
        amountValueStyles={shouldAlertSizeChange ? styles.warning : ''}
        priceValueStyles={shouldAlertPriceChange ? styles.warning : ''}
        securityModalInfo={securityModalInfo}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        tradeAmt={
          <TradeAmountInput
            value={tradeAmt}
            inputDisabled={[
              'accepted',
              'rejected',
              'waitingForConfirmation'
            ].includes(orderStatusComp ?? '')}
            inputClass={
              type === 'buy' ? styles.buySecondColor : styles.sellSecondColor
            }
            minimumSize={securityModalInfo.minimumSize}
            onChange={onChangeTradeAmt}
          />
        }
        updateOnChanges={[
          securityModalInfo.price,
          securityModalInfo.allInPrice,
          securityModalInfo.yieldToWorst,
          securityModalInfo.benchmarkPrice,
          securityModalInfo.hedgeNotional
        ]}
      />
      <div className={rightSideInfoClasses}>
        <div className={styles.allInPrice}>
          <p>All-in Price</p>
          <p
            data-testid="aggressor-all-in-price"
            className={cx(
              styles.value,
              shouldAlertPriceChange && styles.warning
            )}
          >
            {formatAggressorLLPrice(allInPrice, currency, isTreasury)}
          </p>
          {showTsy && (
            <PriceHedge
              disableBtns={disableButton}
              disableCrossBtn={disableCrossButton}
              setSpotCrossSelection={setSpotCrossSelection}
              spotCrossSelection={spotCrossSelection}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AggressorModalContent
