import { ColDef, GridApi } from '@ag-grid-community/core'
import { useCallback, useMemo } from 'react'

export const applyColumnsOrder = <T = any>(
  columnsOrder: string[] | undefined,
  columnDefinitions: Array<ColDef<T>>
) => {
  const colDef = columnDefinitions
  const columnDefs = []

  if (columnsOrder && columnsOrder.length) {
    for (const c of columnsOrder) {
      // @ts-ignore
      const col = colDef.find((column) => column.colId === c)

      if (col) {
        col.hide = false
        // columnDefs.push(col)
        columnDefs.push(Object.assign({}, col))
      }
    }
    for (const c of colDef) {
      const col = columnDefs.find((column) => column.colId === c.colId)
      if (!col) {
        c.hide = true
        // columnDefs.push(c)
        columnDefs.push(Object.assign({}, c))
      }
    }
  } else {
    return colDef
  }
  return columnDefs
}
export const rowSelection = {
  mode: 'singleRow',
  checkboxes: false,
  enableClickSelection: true
} as const

export const useAdminStickyColumns = <T>(
  columnsOrder: string[],
  columnDefinitions: Array<ColDef<T>>,
  onColumnsChanged: (cols: string[] | undefined) => void
) => {
  const columnDefs = useMemo(() => {
    return applyColumnsOrder(columnsOrder, columnDefinitions)
  }, [columnDefinitions, columnsOrder])

  const handleColumnChange = useCallback(
    ({ api }: { api: GridApi<T> }) => {
      const cols = api.getAllDisplayedColumns().map((col) => {
        return col.getColId()
      })
      if (cols) {
        onColumnsChanged(cols)
      }
    },
    [onColumnsChanged]
  )

  return {
    columnDefs,
    handleColumnChange
  }
}
