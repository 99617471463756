import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { useMemo } from 'react'
import { OrderManager } from '../../../helpers/hooks/useManageMyOrders'
import { getBooks } from '../../../store/books/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppSelector } from '../../../store/types'

interface Props {
  manager: OrderManager
  securityId: number
}

const BookRenderer = ({ manager, securityId }: Props) => {
  const books = useAppSelector(getBooks)
  const order = manager.getStagedOrLiveOrderForSecurity(securityId)
  const book = useMemo(() => {
    if (order?.custId) {
      return books?.find((b) => b.id === order.custId)
    }
  }, [books, order])
  return <>{book?.name}</>
}

export const BidBookRenderer = ({
  data,
  context
}: CustomCellRendererProps<
  SecurityStaticData,
  unknown,
  { buyOrderManager: OrderManager; sellOrderManager: OrderManager }
>) => {
  if (data?.id) {
    return (
      <BookRenderer manager={context.buyOrderManager} securityId={data.id} />
    )
  }
  return <></>
}
export const OfferBookRenderer = ({
  data,
  context
}: CustomCellRendererProps<
  SecurityStaticData,
  unknown,
  { buyOrderManager: OrderManager; sellOrderManager: OrderManager }
>) => {
  if (data?.id) {
    return (
      <BookRenderer manager={context.sellOrderManager} securityId={data.id} />
    )
  }
  return <></>
}
