import {
  CellEditingStoppedEvent,
  CellKeyDownEvent,
  ColDef,
  ProcessDataFromClipboardParams
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { useSecuritySymbols } from '../../../../helpers/hooks/useSecuritySymbols'

import { useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import SearchingSymbolEditor from '../../../CellEditors/SearchingSymbolEditor'
import { rowSelection } from '../../helpers'
import styles from './AdvancedFilterEditor.module.scss'
import { SecuritySymbolRow } from './types'

type Props = {
  symbolsAndCusips: string[]
  setSymbolsAndCusips: (symbolsAndCusips: string[]) => void
}

const rawSymbolsAndCusipsToRowData = (symbol: string) => ({ symbol })
type RowData = SecuritySymbolRow
const minRowcount = 12

const SymbolAndCusipEditor = ({
  symbolsAndCusips,
  setSymbolsAndCusips
}: Props) => {
  // ------------------ security details ------------------ //
  const valueListId = 'admin-watchlist-valueList'
  const [searchString, setSearchString] = useState('')
  const { searchResults, getBoardLabelForSymbol, fetchDetailsForSymbols } =
    useSecuritySymbols(searchString)
  const symbols = symbolsAndCusips.join(',')
  useEffect(() => {
    fetchDetailsForSymbols(symbolsAndCusips)
  }, [symbols])
  // ------------------ interacting with rows ------------------ //
  const [localSymbolsAndCusips, setLocalSymbolsAndCusips] =
    useState(symbolsAndCusips)

  const rowData = useMemo(() => {
    const populatedRows = localSymbolsAndCusips.filter((value) => value)
    const extraRowsNeeded = Math.max(1, minRowcount - populatedRows.length)
    const extraRows = ','.repeat(extraRowsNeeded).split(',')
    return [...populatedRows, ...extraRows].map(rawSymbolsAndCusipsToRowData)
  }, [localSymbolsAndCusips])
  const gridRef = useRef<AgGridReact>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const theme = useAppSelector(getCurrentTheme)
  const classes = `${theme} ${styles['symbol-cusip-grid']}`

  const columnDefs = useMemo<Array<ColDef<RowData>>>(() => {
    return [
      {
        cellEditor: SearchingSymbolEditor,
        cellEditorParams: {
          selector: 'admin-wl',
          setSearchString,
          valueListId
        },
        editable: true,
        headerName: 'Bond Ticker, Cusip, or Isin',
        field: 'symbol' as const,
        flex: 100,
        tooltipValueGetter: ({ value }) => getBoardLabelForSymbol(value)
      }
    ]
  }, [getBoardLabelForSymbol])

  const onCellEditingStopped = useCallback(
    ({ api, valueChanged }: CellEditingStoppedEvent) => {
      if (valueChanged) {
        const symbolsArr: string[] = []
        api.forEachNode(({ data }) => {
          if (data.symbol) {
            const symbol = data.symbol.trim()
            if (!symbol.includes(' ')) {
              symbolsArr.push(symbol)
            } else {
              symbolsArr.push(...symbol.split(' '))
            }
          }
        })
        setLocalSymbolsAndCusips(symbolsArr)
      }
    },
    [setSymbolsAndCusips]
  )

  const processPastedSymbols = useCallback(
    ({ data, api }: ProcessDataFromClipboardParams<RowData>) => {
      const pasted = data.map((value) => value[0]).filter((item) => item)
      const pasteRow = Math.min(
        api?.getFocusedCell()?.rowIndex ?? 0,
        localSymbolsAndCusips.length
      )
      const prev = localSymbolsAndCusips.slice(0, pasteRow)
      const symbolsArr = [...prev, ...pasted]
      setLocalSymbolsAndCusips(symbolsArr)
      return data
    },
    [localSymbolsAndCusips]
  )

  const onCellkeyDown = useCallback(
    ({ event, api }: CellKeyDownEvent<RowData>) => {
      const keyboardEvent = event as KeyboardEvent
      if (keyboardEvent?.key === 'Delete') {
        // data for the cell is already null here, so we have to do this the hard way
        const updatedRows: string[] = []
        api.forEachNode(({ data }) => {
          if (data?.symbol) {
            updatedRows.push(data.symbol)
          }
        })
        setLocalSymbolsAndCusips(updatedRows)
      }
    },
    []
  )

  useEffect(() => {
    setSymbolsAndCusips(localSymbolsAndCusips.filter((item) => item))
  }, [localSymbolsAndCusips])

  useClickAway(containerRef, () => {
    gridRef.current?.api.stopEditing()
  })

  return (
    <div className={classes}>
      <AgGridReact<RowData>
        ref={gridRef}
        columnDefs={columnDefs}
        columnMenu="legacy"
        rowData={rowData}
        onCellEditingStopped={onCellEditingStopped}
        onCellKeyDown={onCellkeyDown}
        processDataFromClipboard={processPastedSymbols}
        suppressClipboardApi={true}
        stopEditingWhenCellsLoseFocus={true}
        enterNavigatesVertically={true}
        enterNavigatesVerticallyAfterEdit={true}
        rowSelection={rowSelection}
        headerHeight={20}
        rowHeight={20}
      />
      <datalist id={valueListId}>
        {searchResults.map((res) => (
          <option key={res}>{res}</option>
        ))}
      </datalist>
    </div>
  )
}

export default React.memo(SymbolAndCusipEditor)
