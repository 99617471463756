import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { defaultFilter } from '../../../store/filter/types'
import { useAppDispatch, useAppSelector } from '../../../store/types'

import { getAdminWatchlists } from '../../../store/watchList/selectors'
import { AdminWatchlist, WatchList } from '../../../store/watchList/types'
import { dateFormat } from '../../Upload/helpers'
import WatchlistEditor from './Editors/WatchlistEditor'
import Grid from './Grid/Grid'

import gridStyles from '../../Grid/grid.module.scss'
import styles from './WatchlistManager.module.scss'

import {
  deleteWatchlist,
  fetchAdminWatchListsAction
} from '../../../store/watchList/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import WatchListManagerHeader from './WatchlistManagerHeader'
import { finHeaderButtons } from '../../ComponentHeader/helpers'

export type Resettable = {
  reset: () => void
}

const createNewWatchlist = () => {
  return {
    name: dayjs().format(dateFormat),
    filter: { ...defaultFilter },
    permission: 'private',
    symbolsAndCusips: [] as string[]
  } as const
}

const WatchlistManager = () => {
  const [selectedWatchlist, setSelectedWatchlist] = useState<
    WatchList | undefined
  >()
  const [newWatchlist, setNewWatchlist] = useState<AdminWatchlist>()
  const [version, setVersion] = useState(0)

  const dispatch = useAppDispatch()
  const watchlists = useAppSelector(getAdminWatchlists)

  const resetHandle = useRef<Resettable>(null)

  const deleteSelectedWatchlist = useCallback(() => {
    if (newWatchlist) {
      setNewWatchlist(undefined)
      setVersion((v) => ++v)
      return
    }
    if (selectedWatchlist?.id && selectedWatchlist.canEdit) {
      dispatch(deleteWatchlist(selectedWatchlist.id))
    }
  }, [selectedWatchlist, newWatchlist])

  const onSearch = useCallback(() => {
    resetHandle.current?.reset()
  }, [resetHandle.current])

  const onCreateNewWatchlist = useCallback(() => {
    setNewWatchlist(createNewWatchlist())
    setVersion((v) => ++v)
  }, [])

  useEffect(() => {
    dispatch(fetchAdminWatchListsAction())
  }, [])

  useEffect(
    () => {
      setVersion((v) => ++v)
      const updatedWatchlist = watchlists?.find(
        (watchlist) => watchlist.id === selectedWatchlist?.id
      )
      if (watchlists && selectedWatchlist && !updatedWatchlist) {
        setSelectedWatchlist(watchlists[0])
      }
      if (updatedWatchlist && updatedWatchlist !== selectedWatchlist) {
        setSelectedWatchlist(updatedWatchlist)
      }
    },
    /*  We deliberately only want to update this when watchlists changes to
        force a redraw of the editor and to reset selectedWatchList if the old/stale
        watchlist has been deleted or replaced.
    */
    [watchlists]
  )

  const editingWatchlist = newWatchlist || selectedWatchlist

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Watchlist Manager"
        headerButtons={finHeaderButtons('WatchlistManager')}
      />
      <div className={styles.content}>
        <WatchListManagerHeader
          onSearch={onSearch}
          createNewWatchlist={onCreateNewWatchlist}
        />
        <Grid
          watchlists={watchlists}
          selectWatchlist={setSelectedWatchlist}
          selectedWatchlist={selectedWatchlist}
          deleteSelectedWatchlist={deleteSelectedWatchlist}
          ref={resetHandle}
        />
        <WatchlistEditor
          watchlist={editingWatchlist}
          deleteSelectedWatchlist={deleteSelectedWatchlist}
          key={`wle-${selectedWatchlist?.id}-${version}`}
        />
      </div>
    </div>
  )
}

export default WatchlistManager
