import cx from 'classnames'
import React from 'react'

import styles from '../Modal/modal.module.scss'

interface Props {
  disableBtns: boolean
  disableCrossBtn: boolean
  setSpotCrossSelection: (selection: string) => void
  spotCrossSelection: string
}

const getDisabledTitle = (enabled: boolean) => (enabled ? '' : 'Not Available')

const PriceHedge = ({
  disableBtns,
  disableCrossBtn,
  setSpotCrossSelection,
  spotCrossSelection
}: Props) => {
  return (
    <div className={styles.aggressorPriceHedge}>
      Price/hedge options:
      <button
        type="button"
        className={cx(
          styles.aggressorPriceHedgeOption,
          spotCrossSelection === 'S' && styles.selectedButton
        )}
        onClick={() => setSpotCrossSelection('S')}
        disabled={disableBtns}
        title={getDisabledTitle(disableBtns)}
      >
        Auto-SPOT (Tsy)
      </button>
      <button
        type="button"
        className={cx(
          styles.aggressorPriceHedgeOption,
          spotCrossSelection === 'H' && styles.selectedButton
        )}
        onClick={() => setSpotCrossSelection('H')}
        disabled={disableCrossBtn}
        title={getDisabledTitle(disableCrossBtn)}
      >
        Auto-HEDGE (Tsy)
      </button>
    </div>
  )
}

export default PriceHedge
