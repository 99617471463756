import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { receiveHeartbeat, successfulHeartbeat } from './actions'

const heartbeatEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('heartbeat.HeartbeatReceived'),
    switchMap(() => {
      return getHub().invoke('SingleHeartbeat').pipe(map(successfulHeartbeat))
    }),
    takeUntil(action$.pipe(ofType('ws.force-reload', 'auth.redirect-to-login')))
  )

const beginHeartbeatingEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('heartbeat.BeginHeartbeating'),
    mergeMap(() => {
      return getHub().stream('Heartbeat').pipe(map(receiveHeartbeat))
    }),
    takeUntil(action$.pipe(ofType('ws.force-reload', 'auth.redirect-to-login')))
  )

export default combineEpics(beginHeartbeatingEpic, heartbeatEpic)
