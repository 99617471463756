import { EnhancedOrderUploadRow } from '../../components/Upload/EnhancedUpload/types'
import { Book } from '../books/types'
import { Filter } from '../filter/types'
import { StagedOrder } from '../stagedOrders/types'

export interface OrderRowInfo {
  index: number
  identifier: string
  bidAmt?: string
  bidAon?: boolean
  bidPrice?: string
  bidSprd?: string
  ofrAmt?: string
  ofrAon?: boolean
  ofrPrice?: string
  ofrSprd?: string
  orderType?: string
  error?: string
  isin?: string
}

export interface ErrorInfo {
  index: number
  identifier: string
  orderRowInfo?: OrderRowInfo
  errorIndex: number
}

export type OrdersWithSecurityIds = Array<{
  securityIds: number[]
  orderInfo: OrderRowInfo
}>

export type DropdownState =
  | 'closed'
  | 'upload'
  | 'newWatchlist'
  | 'invalidUpload'

export interface WatchlistInfo {
  gridIndex: number
  name: string
  permission: 0 | 1
  book?: Book
  filter: Filter
  myFirmChecked: boolean
  useSizeChecked: boolean
  size: number
  useAdvancedFilter: boolean
}

export type SecurityOrderInfo = {
  identifier: string
  securityIds: number[]
  orders: StagedOrder[]
}
export const permissions = ['My Firm', 'Private'] as const
export type PermissionsType = (typeof permissions)[number]
// ------------------ reducer ------------------ //
export type WorkingWatchlistUpload = {
  name: string
  bookId: number
  permission: PermissionsType
  rows: EnhancedOrderUploadRow[]
  isChanged: boolean
}
